/**
 * 画像ファイルを表すMIMEタイプ
 *
 * @see {@link https://developer.mozilla.org/ja/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types}
 */
export const ImageFileMIME = {
  bmp: 'image/bmp',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
  ico: 'image/vnd.microsoft.icon',
  svg: 'image/svg+xml',
  tiff: 'image/tiff',
} as const;
export type ImageFileMIMEType = typeof ImageFileMIME[keyof typeof ImageFileMIME];
