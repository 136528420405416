import * as React from 'react';
import { ReactNode } from 'react';
import { FilterFormInputProps, List } from '../../components/molucules/custom-react-admin';
import { Datagrid, DateField, FunctionField, ShowButton, TextField, WrapperField } from 'react-admin';
import { CustomLink } from '../../components/atoms/custom-link';

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: '顧客ID', name: 'inquirerId' },
  { type: 'input', label: '問い合わせた人', name: 'inquirerNickname' },
  { type: 'datetime', label: '受付日', name: 'createdAt' },
  { type: 'input', label: '作品ID', name: 'artworkId' },
];

export const ChatList = (props: { children?: ReactNode }) => {
  return (
    <List {...props} title={'チャット管理'} hasCreate={false} filters={filterForm}>
      <Datagrid bulkActionButtons={false} optimized>
        <TextField source="id" label="ルームID" sortable={true} />
        <DateField source="createdAt" label="受付日" sortable={true} showTime locales={'ja-jp'} sortBy={'created_at'} />
        <FunctionField
          label="問い合わせた人"
          render={(record: any) => {
            return (
              <CustomLink resource={'accounts'} type={'show'} id={record.inquirerId} title={record.inquirerNickname} />
            );
          }}
          sortable={true}
          sortBy={'inquirerNickname'}
        />
        <FunctionField
          label="問い合わせを受けた人"
          render={(record: any) => {
            if (record.ownerArtistId) {
              return (
                <CustomLink
                  resource={'artists'}
                  type={'show'}
                  id={record.ownerArtistId}
                  title={record.ownerArtistName}
                />
              );
            }
            return <CustomLink resource={'accounts'} type={'show'} id={record.ownerId} title={record.ownerNickname} />;
          }}
          sortable={true}
          sortBy={'ownerNickname'}
        />
        <FunctionField
          label="作品名"
          render={(record: any) => {
            return <CustomLink resource={'artworks'} type={'show'} id={record.artworkId} title={record.artworkTitle} />;
          }}
          sortable={true}
          sortBy={'artworkTitle'}
        />
        <WrapperField label={'操作'}>
          <ShowButton className={'buttonNoIcon'} label={'表示'} />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
