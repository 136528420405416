import * as React from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { useCreatePath, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { CommonButton } from '../../components/atoms/button/common-button';
import { FRONT_BASE_URL } from '../../util/constant/api-url';

const attrs: IFormInputValue[] = [
  {
    label: 'アカウントID',
    name: 'id',
    render: (record) => {
      const url = record.artistId
        ? `${FRONT_BASE_URL}artist/${record.artistId}`
        : record.exhibitorId
        ? `${FRONT_BASE_URL}exhibitor/${record.id}`
        : `${FRONT_BASE_URL}member/${record.id}`;
      return (
        <a href={url} target={'_blank'}>
          {record.id}
        </a>
      );
    },
  },
  { label: 'HHID', name: 'hhId' },
  { label: 'アイコン画像', name: 'iconPath', type: 'image' },
  {
    label: 'ニックネーム',
    name: 'nickname',
    renderLabel: (record) => (record.artistId ? 'アーティスト名' : 'ニックネーム'),
  },
  { label: 'プロフィール', name: 'profile' },
  {
    label: 'お届け先情報',
    name: 'address',
    render: (record) => {
      return (
        <>
          {record.address && (
            <React.Fragment>
              {(record.address.postCode || '') + ' ' + (record.address.prefecture || '')}
              <br />
              {(record.address.city || '') +
                ' ' +
                (record.address.town || '') +
                ' ' +
                (record.address.buildingInfo || '')}
              <br />
            </React.Fragment>
          )}
        </>
      );
    },
  },
  {
    label: 'お届け先氏名',
    name: 'deliveryName',
    render: (record) => (record.familyName || '') + ' ' + (record.lastName || ''),
  },
  {
    label: '電話番号',
    name: 'phone',
    render: (record) => (record.tel1 || '') + ' - ' + (record.tel2 || '') + ' - ' + (record.tel3 || ''),
  },
  {
    label: 'メールアドレス',
    name: 'email',
    render: (record) => {
      return <a href={`mailto:${record.email}`}>{record.email}</a>;
    },
  },
  { label: 'Stripe ID', name: 'stripeId' },
  { label: '会員種別', name: 'memberType' },
  {
    label: '振り込み情報',
    name: 'transferBox',
    className: 'transferBox',
  },
  { label: '金融機関名', name: 'bankName' },
  { label: '支店名', name: 'branchName' },
  { label: '支店番号', name: 'branchNumber' },
  { label: '預金種別', name: 'depositType' },
  { label: '口座番号', name: 'accountNumber' },
  { label: '口座名義（全角カナ）', name: 'accountName' },
];

const WithdrawDetailButton = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  return (
    <Box
      sx={{
        display: 'inline-block',
        '& a': {
          textDecoration: 'none',
        },
      }}
    >
      <Link to={createPath({ resource: 'balance-histories/account/' + record.id, type: 'list' })}>
        <CommonButton text={'出金履歴詳細'} variant={'black'} />
      </Link>
    </Box>
  );
};

export const AccountShow = () => {
  return (
    <Show recordTitleAttribute={'id'} hasEdit={false} buttons={<WithdrawDetailButton />}>
      <Box sx={styles}>
        <RecordInfo data={attrs} />
      </Box>
    </Show>
  );
};

const styles = {
  '& .transferBox': {
    margin: '50px 30px 0 0',
    '& .formGroupLabel ': {
      '& label': {
        color: '#000 !important',
      },
    },
  },
};
