import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { getFileUrl } from '../../../util/function';

export const convertToHtml = (editorState: EditorState) => {
  return convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return <a href={entity.data.url}>{originalText}</a>;
      }

      if (entity.type === 'IMAGE') {
        return <img src={getFileUrl(entity.data.src)} />;
      }

      return originalText;
    },
  })(editorState.getCurrentContent());
};
