import * as React from 'react';
import { useState } from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { Box } from '@mui/material';
import { CommonButton } from '../../components/atoms/button/common-button';
import { useRecordContext, useRefresh, useNotify } from 'react-admin';
import { getWithdrawalState } from './list';
import { numberFormat } from '../../util/function';
import { ModalCancel } from '../../components/molucules/modal/modal-cancel';
import { axiosBackendClient } from '../../util/axios';

const attrs: IFormInputValue[] = [
  { label: '申請ID', name: 'id' },
  { label: '申請日', name: 'createdAt', type: 'datetime' },
  {
    label: '状態',
    name: 'withdrawalState',
    render: (record: any) => {
      return getWithdrawalState(record);
    },
  },
  { label: '会員種別', name: 'memberType' },
  { label: 'ユーザー名', name: 'nickname' },
  {
    label: '取引き合算金額',
    name: 'amount',
    render: (record: any) => {
      const value = record.amount > 0 ? record.amount : record.amount * -1;
      return numberFormat(value);
    },
  },
  {
    label: '振り込み情報',
    name: 'transferBox',
    className: 'transferBox',
  },
  { label: '金融機関名', name: 'bankName' },
  { label: '支店名', name: 'branchName' },
  { label: '支店番号', name: 'branchNumber' },
  {
    label: '預金種別',
    name: 'depositType',
  },
  { label: '口座番号', name: 'accountNumber' },
  { label: '口座名義（全角カナ）', name: 'accountName' },
];

const CancelButton = ({ onClick }: { onClick: (record: any) => void }) => {
  const record = useRecordContext();

  return (
    <>
      <CommonButton
        text={'キャンセル'}
        type={'button'}
        variant={'black'}
        className={''}
        onClick={() => {
          onClick(record);
        }}
      />
    </>
  );
};

export const BalanceHistoryShow = () => {
  const refresh = useRefresh();
  const notify = useNotify();

  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState<string>('');
  const [isCallApi, setIsCallApi] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelClick = (record: any) => {
    setId(record.id);
    setOpenModal(true);
  };

  const handleCancelBalanceHistory = async () => {
    if (isCallApi) {
      return;
    }
    setIsCallApi(true);

    try {
      await axiosBackendClient.post<ResponseType>(
        '/balance-histories/cancel/' + id,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      refresh();

      notify('キャンセル完了');
    } catch (e) {
      // @ts-ignore
      notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
    }

    setIsCallApi(false);
    setOpenModal(false);
  };

  return (
    <Show title={'利益金申請詳細'} hasEdit={false} buttons={<CancelButton onClick={handleCancelClick} />}>
      <Box sx={styles}>
        <RecordInfo data={attrs} />
      </Box>
      <ModalCancel id={id} open={openModal} onClose={handleCloseModal} onSubmit={handleCancelBalanceHistory} />
    </Show>
  );
};

const styles = {
  '& .transferBox': {
    margin: '50px 30px 0 0',
    '& .formGroupLabel ': {
      '& label': {
        color: '#000 !important',
      },
    },
  },
};
