import React from 'react';
import { SvgIcon } from '@mui/material';

export const Logo = (): JSX.Element => {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 160 24.526'}>
      <g transform="translate(-58.994 -696.971)">
        <path
          d="M116.67,14.266a10.421,10.421,0,0,1,3.493.5,5.812,5.812,0,0,1,2.174,1.295,4.405,4.405,0,0,1,1.112,1.766,6.162,6.162,0,0,1,.314,1.912,5.978,5.978,0,0,1-.717,3.061,5.113,5.113,0,0,1-2.315,1.989l3.872,8.163h-3.46L117.8,24.91c-.157.018-.323-.009-.5,0s-.358,0-.549,0H113.4v8.035h-3.113V14.266Zm.236,8.633a6.015,6.015,0,0,0,1.779-.222,2.683,2.683,0,0,0,1.112-.628,2.278,2.278,0,0,0,.576-.981,4.526,4.526,0,0,0,.17-1.282c0-3.068-2.007-3.469-3.95-3.469H113.4V22.9Z"
          transform="translate(-16.238 687.243)"
        />
        <path
          d="M464.728,14.266a10.421,10.421,0,0,1,3.493.5,5.812,5.812,0,0,1,2.171,1.295,4.4,4.4,0,0,1,1.112,1.766,6.162,6.162,0,0,1,.314,1.912A5.981,5.981,0,0,1,471.1,22.8a5.115,5.115,0,0,1-2.315,1.989l3.869,8.161H469.2l-3.349-8.035c-.157.018-.323-.009-.5,0s-.359,0-.549,0h-3.349v8.035h-3.113V14.266Zm.236,8.633a6.015,6.015,0,0,0,1.779-.222,2.683,2.683,0,0,0,1.112-.628,2.278,2.278,0,0,0,.576-.981,4.525,4.525,0,0,0,.17-1.282c0-3.068-2.007-3.469-3.95-3.469h-3.191V22.9Z"
          transform="translate(-253.661 687.243)"
        />
        <path
          d="M173.886,16.456h-5.914V14.273h14.965v2.183h-5.887v16.5h-3.166Z"
          transform="translate(-55.587 687.234)"
        />
        <path
          d="M234.34,14.273h12.425v2.183h-9.26v5.7h8.739V24.43h-8.739V30.7h9.76l-.366,2.251H234.34Z"
          transform="translate(-100.858 687.234)"
        />
        <path
          d="M395.569,14.273h12.425v2.183h-9.259v5.7h8.739V24.43h-8.739V30.7h9.76l-.366,2.251H395.569Z"
          transform="translate(-210.84 687.234)"
        />
        <path d="M300.269,14.273v16.4h9.262l-.393,2.277H297.1V14.273Z" transform="translate(-143.672 687.234)" />
        <rect width="3.165" height="18.68" transform="translate(172.384 701.508)" />
        <path
          d="M22.869,0s-6.553,14-6.88,14.655c-1.637,0-1.964-.185-5.892-.185a17.136,17.136,0,0,0-3.788.415,12.621,12.621,0,0,0-3.266,1.193A6.762,6.762,0,0,0,.785,17.957,3.925,3.925,0,0,0,0,20.426,3.228,3.228,0,0,0,.537,22.2,4.4,4.4,0,0,0,1.7,23.235a8.2,8.2,0,0,0,3.363,1.2,9.342,9.342,0,0,0,4.19-.375q.119-.039.237-.082a12.007,12.007,0,0,0,5.936-3.54,23.965,23.965,0,0,0,2.756-3.593h6.091l.019,6.328h3.14V0ZM7.431,21.276c-.468-.078-1.667-.526-1.863-1.52-.29-1.471,1.438-2.468,2.448-2.8,2.309-.765,6.991-.333,6.991-.333a13.328,13.328,0,0,1-4.256,3.928,5.354,5.354,0,0,1-3.321.728M24.271,14.8H19.123L24.289,4.223Z"
          transform="translate(58.994 696.971)"
        />
      </g>
    </SvgIcon>
  );
};
