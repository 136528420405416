import * as React from 'react';
import { ReactNode } from 'react';
import { Datagrid, TextField, EditButton, WrapperField, FunctionField, DateField } from 'react-admin';
import { List, ImageField, FilterFormInputProps } from '../../components/molucules/custom-react-admin';
import { Box, InputLabel } from '@mui/material';
import { LABEL_WIDTH, LABEL_STYLE } from '../../util/constant/form';
import { VDatePicker } from '../../components/atoms/form';
import { CustomLink } from '../../components/atoms/custom-link';
import { ExportCsvForm } from '../../components/molucules/custom-react-admin/export-csv-form';
import { BASE_API_URL } from '../../providers/data-provider';

const CreatedAtFilter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .vPriceLabel': {
          width: '100%',
          maxWidth: LABEL_WIDTH,
        },
        '& .vPriceSpacing': {
          marginLeft: '10px',
        },
        '& .vPriceInput': {
          maxWidth: '40%',
        },
      }}
    >
      <Box className={'vPriceLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>申請日</InputLabel>
      </Box>
      <Box className={'vPriceInput'}>
        <VDatePicker name={'createdAtFrom'} />
      </Box>
      <Box className={'vPriceSpacing'}>~</Box>
      <Box className={'vPriceSpacing vPriceInput'}>
        <VDatePicker name={'createdAtTo'} />
      </Box>
    </Box>
  );
};

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: '申請ID', name: 'id' },
  { type: 'input', label: 'HHID', name: 'hhId' },
  { type: 'input', label: 'アーティスト名', name: 'artistName' },
  { type: 'render', label: '申請日', name: 'created_at', render: () => <CreatedAtFilter /> },
];

export const ArtistApplicantList = (props: { children?: ReactNode }) => {
  return (
    <List {...props} title={'アーティスト申請管理一覧'} hasCreate={false} filters={filterForm}>
      <ExportCsvForm apiUrl={BASE_API_URL + '/artist-applicants/export-csv'} />
      <Datagrid bulkActionButtons={false}>
        <TextField label="申請ID" source="id" sortable={true} />
        <TextField
          label="ステータス"
          source="applicantStatus"
          sortable={true}
          sortBy={'applicant_status'}
          textAlign={'center'}
        />
        <FunctionField
          label="アーティスト名"
          render={(record: any) => {
            return <CustomLink resource={'accounts'} type={'show'} id={record.accountId} title={record.artistName} />;
          }}
          sortable={true}
          sortBy={'artist_name'}
        />
        <ImageField label="審査画像" source="examinationImage1" sortable={false} />
        <TextField label="コメント" source="comment" sortable={false} />
        <DateField source="createdAt" label="申請日" sortable={true} showTime locales={'ja-jp'} sortBy={'created_at'} />

        <WrapperField label={'操作'}>
          <EditButton className={'buttonNoIcon'} label={'詳細'} />
          {/*<ShowButton className={'buttonNoIcon'} label={'表示'} />*/}
        </WrapperField>
      </Datagrid>
    </List>
  );
};
