import { Box, Card, InputLabel } from '@mui/material';
import { LABEL_STYLE, LABEL_WIDTH } from '../../util/constant/form';
import * as React from 'react';
import { useVForm, VDatePicker, VFormContainer } from '../../components/atoms/form';
import { CommonButton } from '../../components/atoms/button/common-button';
import { useWatch } from 'react-hook-form';
import { BASE_API_URL } from '../../providers/data-provider';

export const ExportForm = () => {
  const formContext = useVForm({
    defaultValues: {},
  });

  const createAtToValue = useWatch({
    // @ts-ignore
    name: 'createdAtTo',
    control: formContext.control,
    defaultValue: '',
  });

  const handleExportCsv = (data: any) => {
    const filter = { createdAtFrom: data.createdAtFrom, createdAtTo: data.createdAtTo };
    const url = BASE_API_URL + '/artists/export-csv?filter=' + encodeURIComponent(JSON.stringify(filter));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', url);
    // link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleResetForm = () => {
    formContext.reset({});
  };
  const dateFromTo = (value: string | undefined) => {
    if (value && createAtToValue) {
      return value < createAtToValue ? true : '登録日Fromは登録日To以前の日付にしてください。';
    }
    return true;
  };
  return (
    <Card sx={styles}>
      <Box className={'exportFormTitle'}>csv出力</Box>
      <Box className={'exportFormBody'}>
        <VFormContainer formContext={formContext} onSuccess={handleExportCsv}>
          <Box className={'vPrice'}>
            <Box className={'vPriceLabel'}>
              <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>登録日</InputLabel>
            </Box>
            <Box className={'vPriceInput'}>
              <VDatePicker name={'createdAtFrom'} validation={{ validate: dateFromTo }} />
            </Box>
            <Box className={'vPriceSpacing'}>~</Box>
            <Box className={'vPriceSpacing vPriceInput'}>
              <VDatePicker name={'createdAtTo'} />
            </Box>
          </Box>
          <Box className={'exportFormButton'}>
            <CommonButton text={'csv出力'} type={'submit'} />
            <CommonButton
              text={'リセット'}
              type={'button'}
              variant={'white'}
              className={'exportFormButtonReset'}
              onClick={handleResetForm}
            />
          </Box>
        </VFormContainer>
      </Box>
    </Card>
  );
};

const styles = {
  margin: '16px 0',
  borderRadius: '5px',
  '& .exportFormTitle': {
    padding: '16px',
    background: '#F5F5F5',
  },
  '& .exportFormBody': {
    padding: '16px 0',
    '& .exportFormRow': {
      padding: '16px',
    },
  },

  '& .vPrice': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .vPriceLabel': {
    width: '100%',
    maxWidth: LABEL_WIDTH,
  },
  '& .vPriceSpacing': {
    marginLeft: '10px',
  },
  '& .vPriceInput': {
    maxWidth: '40%',
  },
  '& .exportFormButton': {
    padding: '16px 0',
    paddingLeft: {
      xs: '0',
      sm: '200px',
    },

    '& .exportFormButtonReset': {
      marginLeft: '10px',
    },
  },
};
