import { axiosBackendClient } from '../../util/axios';
import { API_UPLOAD_IMAGE } from '../../util/constant/api-url';

/**
 * S3へのアップロードAPIのレスポンスの型
 *
 * @see backend/src/infrastructure/aws/s3/s3-api.ts
 * @example
 * ["01GFSHVW5EJRFZEK0BW8G1ZX3P.png", "01GFSHVW5EJRFZEK0BW8G1BF32.png"]
 */
type ResponseType = Array<string>;

/**
 * 1つのファイルをS3へアップロードする
 *
 * @param file
 * @returns string "01GFSHVW5EJRFZEK0BW8G1ZX3P.png"
 */
export async function imageUploader(file: File) {
  try {
    const formData = new FormData();
    formData.append('files', file);
    const response = await axiosBackendClient.post<ResponseType>(API_UPLOAD_IMAGE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response && response.data.length) {
      return response.data[0];
    } else {
      throw new Error(
        `画像ファイルのファイルパスが得られませんでした。 | ${file.name} ${file.type} (${file.size}bytes)`,
      );
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
