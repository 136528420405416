import * as React from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { className as cl } from '../../../util/function';
import { VTextField, VSelect, VDatePicker } from '../../atoms/form';

export type FilterFormInputProps = {
  type: 'input' | 'select' | 'radio' | 'checkbox' | 'hidden' | 'datetime' | 'render';
  label: string;
  name: string;
  className?: string;
  show?: boolean;
  options?: any[];
  defaultValue?: any;
  size?: 6 | 12;
  render?: () => React.ReactNode;
};

export const FilterFormInput = ({
  type,
  label,
  name,
  className = '',
  show = true,
  options,
  render,
}: FilterFormInputProps) => {
  // const { filterElement, handleHide, className } = props;
  // const resource = useResourceContext(props);
  // const translate = useTranslate();
  useEffect(() => {
    if (type === 'select' || type === 'radio' || type === 'checkbox') {
      if (!options) {
        throw new Error('Options is required if type is select | radio | checkbox');
      }
    }
  }, [type, options]);

  return (
    <>
      {show && (
        <Box className={cl([className, 'filterFieldInput'])}>
          {type === 'input' && <VTextField name={name} label={label} />}
          {type === 'select' && <VSelect name={name} label={label} options={options} />}
          {type === 'datetime' && <VDatePicker name={name} label={label} />}
          {type === 'render' && render && render()}
        </Box>
      )}
    </>
  );
};
