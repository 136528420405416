import React from 'react';
import { useRedirect } from 'react-admin';
import { CommonButton } from '../../atoms/button/common-button';

interface props {
  resource?: string;
  to?: string;
  variant?: 'blue' | 'green';
  className?: string;
  label?: string;
}

export const BackButton = ({
  resource,
  to = 'list',
  variant = 'blue',
  className,
  label = '戻る',
}: props): JSX.Element => {
  const redirect = useRedirect();
  const handleClick = () => {
    redirect(to, resource);
  };

  return <CommonButton variant={variant} type={'button'} onClick={handleClick} text={label} className={className} />;
};
