import * as React from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { CommonButton } from '../../components/atoms/button/common-button';
import { useCreatePath, useRedirect, useResourceContext } from 'react-admin';
import { useRecordContext } from 'ra-core';

export const UnsettledBalanceHistoryInputs: IFormInputValue[] = [
  {
    label: '注文ID',
    name: 'id',
  },
  {
    label: '注文者ID',
    name: 'ordererId',
  },
  {
    label: '注文者名',
    name: 'ordererName',
  },
  {
    label: '出品者ID',
    name: 'exhibitorId',
  },
  {
    label: '出品者名',
    name: 'exhibitorName',
  },
  {
    label: '作品ID',
    name: 'artworkId',
  },
  {
    label: '作品名',
    name: 'artworkName',
  },
  {
    label: 'プラン',
    name: 'rentalPlan',
  },

  {
    label: '注文日',
    name: 'orderAt',
    type: 'datetime',
  },
  {
    label: '発送日',
    name: 'shipAt',
    type: 'datetime',
  },
  {
    label: '到着日',
    name: 'completeAt',
    type: 'datetime',
  },
  {
    label: '状態',
    name: 'action',
  },
  {
    label: '状態更新日',
    name: 'statusUpdatedAt',
    type: 'datetime',
  },
  {
    label: '収益計上額',
    name: 'revenue',
    type: 'number',
  },
  {
    label: '前回計上日',
    name: 'lastAccountingDate',
    type: 'datetime',
  },
];

const Buttons = () => {
  const createPath = useCreatePath();
  const resource = useResourceContext();
  const record = useRecordContext();
  const redirect = useRedirect();

  const handleClickEdit = () => {
    redirect(createPath({ type: 'edit', resource, id: record.id }));
  };

  return (
    <>
      <CommonButton text={'収益を編集する'} type={'button'} variant={'black'} onClick={handleClickEdit} />
      <CommonButton text={'収益を計上する'} type={'button'} variant={'blue'} className={'ml-10'} />
    </>
  );
};

export const UnsettledBalanceHistoriesShow = () => {
  return (
    <Show recordTitleAttribute={'id'} hasEdit={false} buttons={<Buttons />}>
      <RecordInfo data={UnsettledBalanceHistoryInputs} />
    </Show>
  );
};
