//@ts-nocheck
import { convertFromHTML } from 'draft-convert';

export const convertFromHtml = (html: string) => {
  const converter = convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'img') {
        return createEntity('IMAGE', 'IMMUTABLE', {
          src: node.getAttribute('src'),
        });
      }
      if (nodeName === 'a') {
        return createEntity('LINK', 'MUTABLE', {
          url: node.getAttribute('href'),
        });
      }
    },

    htmlToBlock: (nodeName, node, lastList, inBlock) => {
      if ((nodeName === 'p' || nodeName === 'div') && inBlock === 'blockquote') {
        return 'blockquote';
      }

      if (nodeName === 'figure' && node.firstChild.nodeName === 'IMG') {
        return {
          type: 'atomic',
          data: {
            atomicType: 'image',
            src: node.firstChild.getAttribute('src'),
          },
        };
      }

      if (nodeName === 'img' && inBlock !== 'atomic') {
        return 'atomic';
      }

      if (nodeName === 'header') {
        return 'header';
      }
    },
  })(html);

  return converter;
};
