import { useMemo } from 'react';
import { ComponentWithoutChildren } from 'src/types/component';
import { RecordInfo, Show, type IFormInputValue } from 'src/components/molucules/custom-react-admin';
import { type INote } from 'src/interface/note';
import { NOTE_CATEGORY_FIELD_LABEL, NOTE_OPEN_STATUS_FIELD_LABEL } from 'src/util/constant/label';

const NewsShow: ComponentWithoutChildren = () => {
  const data: Array<IFormInputValue> = useMemo(() => {
    return [
      { label: 'コンテンツID', name: 'id' },
      { label: 'カテゴリ', name: 'category', render: (record: INote) => NOTE_CATEGORY_FIELD_LABEL[record.category] },
      { label: 'サムネイル画像', name: 'thumbnailImageUrl', type: 'image' },
      { label: 'タイトル', name: 'title' },
      {
        label: '本文',
        name: 'body',
        type: 'richText',
      },
      {
        label: '公開ステータス',
        name: 'openStatus',
        render: (record: INote) => NOTE_OPEN_STATUS_FIELD_LABEL[record.openStatus],
      },
      { label: '公開日時', name: 'openedAt' },
    ];
  }, []);

  return (
    <Show recordTitleAttribute={'title'} hasBack hasEdit>
      <RecordInfo data={data} />
    </Show>
  );
};

export default NewsShow;
