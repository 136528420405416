import React, { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';

interface IImage {
  src: string;
  alt?: string;
  maxWidth?: number;
}

export const Image = ({ src, alt, maxWidth }: IImage): JSX.Element => {
  const [innerSrc, setInnerSrc] = useState(src || '');
  useEffect(() => {
    setInnerSrc(src);
  }, [src]);

  const styles = {
    maxWidth: maxWidth ? maxWidth + 'px' : '100%',
    width: 'auto',
    height: 'auto',
    borderRadius: 0,
    backgroundColor: 'inherit',
    '& img': { maxWidth: '100%', height: 'auto' },
    '& svg': {
      maxWidth: '100%',
      height: 'auto',
      color: '#000',
      minWidth: maxWidth && maxWidth < 100 ? maxWidth + 'px' : '100px',
    },
  };
  return (
    <Avatar variant="rounded" sx={styles}>
      {innerSrc && <img src={innerSrc} alt={alt} onError={() => setInnerSrc('')} />}
    </Avatar>
  );
};
