import * as React from 'react';
import { useEffect, useLayoutEffect as reactUseLayoutEffect } from 'react';
const useLayoutEffect = typeof window !== 'undefined' ? reactUseLayoutEffect : useEffect;

let serverHandoffComplete = false;
let id = 0;
function genId() {
  return ++id;
}

/**
 * useId
 *
 * Autogenerate IDs to facilitate WAI-ARIA and server rendering.
 *
 * Note: The returned ID will initially be `null` and will update after a
 * component mounts. Users may need to supply their own ID if they need
 * consistent values for SSR.
 *
 * @see Docs https://reach.tech/auto-id
 */
function useId(providedId?: number | string | undefined | null) {
  // TODO: Remove error flag when updating internal deps to React 18. None of
  // our tricks will play well with concurrent rendering anyway.

  if (typeof React.useId === 'function') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const id = React.useId();
    return providedId != null ? providedId : id;
  }

  // If this instance isn't part of the initial render, we don't have to do the
  // double render/patch-up dance. We can just generate the ID and return it.
  const initialId = providedId ?? (serverHandoffComplete ? genId() : null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [id, setId] = React.useState(initialId);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    if (id === null) {
      // Patch the ID after render. We do this in `useLayoutEffect` to avoid any
      // rendering flicker, though it'll make the first render slower (unlikely
      // to matter, but you're welcome to measure your app and let us know if
      // it's a problem).
      setId(genId());
    }
  }, [id]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (!serverHandoffComplete) {
      // Flag all future uses of `useId` to skip the update dance. This is in
      // `useEffect` because it goes after `useLayoutEffect`, ensuring we don't
      // accidentally bail out of the patch-up dance prematurely.
      serverHandoffComplete = true;
    }
  }, []);

  return providedId ?? id ?? undefined;
}
const useUniqueId = (prefix?: string): string => {
  return (prefix ? prefix + '-' : '') + useId();
};

export default useUniqueId;
