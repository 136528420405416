import { useState, useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import { CommonButton } from '../../atoms/button/common-button';
import * as React from 'react';

type IModalCancel = {
  id?: string;
  open?: boolean;
  onSubmit?: () => void;
  onClose?: (reason?: 'cancel' | undefined) => void;
  title?: string;
};

export const ModalCancel = ({ id, open, onClose, onSubmit, title }: IModalCancel) => {
  const [innerOpen, setInnerOpen] = useState(open || false);
  useEffect(() => {
    setInnerOpen(!!open);
  }, [open]);

  const handleOkModal = async () => {
    try {
      onSubmit && (await onSubmit());
    } catch (e) {
      console.log(e);
    }
    setInnerOpen(false);
  };

  const handleCancelModal = () => {
    setInnerOpen(false);
    onClose && onClose('cancel');
  };

  return (
    <Modal
      open={innerOpen}
      onClose={() => onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyles}>
        <Box className={'modalTitle'}>{title ? title : `本当に［${id}］をキャンセルしますか？`}</Box>
        <Box className={'modalButton'}>
          <CommonButton text={'はい'} type={'button'} variant={'black'} className={''} onClick={handleOkModal} />
          <CommonButton text={'戻る'} type={'button'} variant={'gray'} className={''} onClick={handleCancelModal} />
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyles = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  '& .modalTitle': {
    textAlign: 'center',
    fontWeight: 600,
  },
  '& .modalButton': {
    marginTop: '50px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    '& .MuiButton-root': {
      minWidth: '100px',
    },
  },
};
