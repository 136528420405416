import * as React from 'react';
import { ReactNode, useState } from 'react';
import { CommonExporter, FilterFormInputProps, List } from '../../components/molucules/custom-react-admin';
import {
  BulkExportButton,
  Datagrid,
  DateField,
  ShowButton,
  TextField,
  useListContext,
  useNotify,
  useRefresh,
  WrapperField,
  FunctionField,
} from 'react-admin';
import { Box } from '@mui/material';
import { CommonButton } from '../../components/atoms/button/common-button';
import { format } from 'date-fns';
import { axiosBackendClient } from '../../util/axios';
import { numberFormat } from '../../util/function';
import { ModalCancel } from '../../components/molucules/modal/modal-cancel';

export const getWithdrawalState = (record: any) => {
  if (!record) {
    return '';
  }
  if (record.withdrawalState === 'CHECK' && record.amount < 0 && !record.isFee) {
    return '入金申請中';
  }

  if (record.withdrawalState === 'DONE' && record.amount < 0 && !record.isFee) {
    return '入金済み';
  }

  if (record.withdrawalState === 'OTHER') {
    return 'その他';
  }

  return '';
};

export const withdrawalOptions: any[] = [
  { id: 'OTHER', name: 'その他', label: 'その他' },
  { id: 'CHECK', name: '入金申請中', label: '入金申請中' },
  { id: 'DONE', name: '入金済み', label: '入金済み' },
];

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: 'アカウントID', name: 'accountId' },
  { type: 'datetime', label: '申請日', name: 'createdAt' },
  { type: 'input', label: 'ユーザー名', name: 'nickname' },
  { type: 'select', label: '状態', name: 'withdrawalState', options: withdrawalOptions },
];

const UpdateStateButton = ({ label, state }: { label: string; state: string }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { selectedIds } = useListContext();
  const [isCallApi, setIsCallApi] = useState(false);

  const handleClick = async () => {
    if (isCallApi) {
      return;
    }
    if (selectedIds?.length) {
      try {
        await axiosBackendClient.post<ResponseType>('/balance-histories/update-state', {
          ids: selectedIds,
          state: state,
        });
        refresh();
        notify('成功');
      } catch (e) {
        // @ts-ignore
        notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
      }
    }
    setIsCallApi(false);
  };

  return <CommonButton text={label} className={'listButtonCommon buttonNoIcon'} onClick={handleClick} />;
};

export const BalanceHistoryList = (props: { children?: ReactNode }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState<string>('');
  const [isCallApi, setIsCallApi] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelClick = (id: any) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCancelBalanceHistory = async () => {
    if (isCallApi) {
      return;
    }
    setIsCallApi(true);

    try {
      await axiosBackendClient.post<ResponseType>(
        '/balance-histories/cancel/' + id,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      refresh();

      notify('キャンセル完了');
    } catch (e) {
      // @ts-ignore
      notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
    }

    setIsCallApi(false);
    setOpenModal(false);
  };

  const exporter = CommonExporter({
    headers: [
      'id',
      'createdAt',
      'withdrawalState',
      'memberType',
      'nickname',
      'amount',
      'profit',
      'bankName',
      'branchName',
      'branchNumber',
      'depositType',
      'accountNumber',
      'accountName',
    ],
    rename: [
      'ID',
      '申請日',
      '状態',
      '会員種別',
      'ユーザー名',
      '取引き合算金額',
      '利益金合算',
      '金融機関名',
      '支店名',
      '支店番号',
      '預金種別',
      '口座番号',
      '口座名義（全角カナ）',
    ],
    transformData: (item: any) => {
      return {
        ...item,
        withdrawalState:
          withdrawalOptions.find((option) => option.id === item.withdrawalState)?.label ?? item.withdrawalState ?? '',
        createdAt: item.createdAt ? format(new Date(item.createdAt), 'Y-m-d H:i:s').toString() : '',
      };
    },
  });

  return (
    <List {...props} title={'収益金管理'} hasCreate={false} filters={filterForm}>
      <>
        <Box className={'listTitle'}>
          <Box className={'listTitleButton'}>
            <UpdateStateButton label={'入金済み'} state={'DONE'} />
            <UpdateStateButton label={'その他'} state={'OTHER'} />
            {/*<ExportButton*/}
            {/*  label={'csv出力'}*/}
            {/*  className={'listButtonCommon buttonNoIcon'}*/}
            {/*  maxResults={10000000}*/}
            {/*  exporter={CommonExporter()}*/}
            {/*/>*/}
            <BulkExportButton label={'csv出力'} className={'listButtonCommon buttonNoIcon'} exporter={exporter} />
          </Box>
          <Box sx={{ overflow: 'auto' }} />
        </Box>
        <Datagrid bulkActionButtons={<Box />} optimized>
          <TextField source="accountId" label="アカウントID" sortable={false} sortBy={'account_id'} />
          <DateField
            source="createdAt"
            label="申請日"
            showTime
            locales={'ja-jp'}
            sortable={false}
            sortBy={'created_at'}
          />
          <TextField source="nickname" label="ユーザー名" sortable={false} />
          <FunctionField
            label="取引金額合計"
            render={(record: any) => {
              return record.amount > 0 ? record.amount : numberFormat(record.amount * -1);
            }}
            sortable={false}
          />

          <FunctionField
            label="状態"
            render={(record: any) => {
              return getWithdrawalState(record);
            }}
            sortable={false}
          />

          <WrapperField label="操作">
            <FunctionField
              label="Name"
              render={(record: any) => (
                <CommonButton
                  text={'キャンセル'}
                  type={'button'}
                  className={'buttonNoIcon'}
                  variant={'none'}
                  onClick={() => handleCancelClick(record.id)}
                />
              )}
            />

            <ShowButton className={'buttonNoIcon'} label={'詳細'} />
          </WrapperField>
        </Datagrid>
        <ModalCancel id={id} open={openModal} onClose={handleCloseModal} onSubmit={handleCancelBalanceHistory} />
      </>
    </List>
  );
};
