import React from 'react';
import { VTextField, VTextFieldProps } from '../../form';
import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material';

export type VTextAreaProps = VTextFieldProps & TextareaAutosizeProps;

export default function VTextArea({ maxRows, minRows, ...props }: VTextAreaProps) {
  return (
    <VTextField
      {...props}
      multiline
      InputProps={{
        ...props.InputProps,
        inputComponent: TextareaAutosize,
        inputProps: { maxRows, minRows },
      }}
    />
  );
}
