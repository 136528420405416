import * as React from 'react';
import { ReactNode } from 'react';
import { Datagrid, DateField, FunctionField, ShowButton, TextField, WrapperField } from 'react-admin';
import { FilterFormInputProps, List } from '../../components/molucules/custom-react-admin';
import { ExportCsvForm } from '../../components/molucules/custom-react-admin/export-csv-form';
import { BASE_API_URL } from '../../providers/data-provider';

const MEMBER_TYPE: Record<string, string>[] = [
  { label: '一般会員', id: '一般会員' },
  { label: '二次出品者', id: '二次出品者' },
  { label: 'アーティスト', id: 'アーティスト' },
];

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: 'アカウントID', name: 'id' },
  { type: 'input', label: 'サイト名', name: 'nickname' },
  { type: 'select', label: '会員種別', name: 'member_type', options: MEMBER_TYPE },
];

export const AccountList = (props: { children?: ReactNode }) => {
  return (
    <List {...props} title={'アカウント'} hasCreate={false} filters={filterForm}>
      <ExportCsvForm apiUrl={BASE_API_URL + '/accounts/export-csv'} />
      <Datagrid bulkActionButtons={false}>
        <TextField label="アカウントID" source="id" sortable={true} />
        <TextField label="HHID" source="hhId" sortable={true} sortBy={'hh_id'} />
        <DateField source="createdAt" label="登録日" sortable={true} showTime locales={'ja-jp'} sortBy={'created_at'} />
        <FunctionField
          label="サイト名"
          render={(record: any) => {
            return record.nickname + (record.deletedAt ? '「退会者」' : '');
          }}
          sortable={true}
          sortBy={'nickname'}
        />
        <FunctionField
          label="会員種別"
          render={(record: any) => {
            return record.memberType;
          }}
          sortable={true}
          sortBy={'memberType'}
        />
        <WrapperField label={'操作'}>
          <ShowButton className={'buttonNoIcon'} label={'詳細'} />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
