import { AWS_S3_BUCKET_URL } from '../constant/aws';

export const printf = (str: string, params: { [key: string]: string }) => {
  if (Object.keys(params).length) {
    for (const key in params) {
      str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), params[key]);
    }
  }
  return str;
};

export const className = (args: { [key: string]: boolean } | string[] | string | number): string => {
  const classes: string[] = [];

  if (Array.isArray(args)) {
    if (args.length) {
      args.forEach((name) => {
        if (name) {
          classes.push(name);
        }
      });
    }
  } else if (typeof args === 'object') {
    Object.keys(args).forEach((name) => {
      if (args[name]) {
        classes.push(name);
      }
    });
  } else {
    const tmp = String(args);
    if (tmp) {
      classes.push(tmp);
    }
  }

  return classes.join(' ');
};

export const numberFormat = (value: string | number | null | undefined): string => {
  if (value === 0 || value === '0') {
    return '0';
  }
  if (!value) return '';
  if (!Number(value)) {
    return '';
  }

  return Number(value)
    .toFixed(0)
    .replace(/\d(?=(\d{3})+$)/g, '$&,');
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getFileUrl = (src?: string): string => {
  if (!src) {
    return '';
  }

  if (src.includes('http:') || src.includes('https:') || src.includes('blob:')) {
    return src;
  }

  return AWS_S3_BUCKET_URL + src;
};
