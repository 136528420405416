import * as React from 'react';
import { ImageFieldProps, ImageFieldClasses, sanitizeFieldRestProps } from 'react-admin';
import { useRecordContext } from 'ra-core';
import get from 'lodash/get';
import { Box, BoxProps, Typography } from '@mui/material';
import { getFileUrl } from '../../../util/function';

const Root = ({ children, ...props }: BoxProps) => {
  return <Box {...props}>{children}</Box>;
};

export const ImageField = (props: ImageFieldProps) => {
  const { className, emptyText, source = '', src = '', title = '', ...rest } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source);

  if (!sourceValue) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : (
      <div className={className} {...sanitizeFieldRestProps(rest)} />
    );
  }

  if (Array.isArray(sourceValue)) {
    return (
      <Root className={className} {...sanitizeFieldRestProps(rest)}>
        <ul className={ImageFieldClasses.list}>
          {sourceValue.map((file, index) => {
            const fileTitleValue = get(file, title) || title;
            const srcValue = getFileUrl(get(file, src) || title);

            return (
              <li key={index}>
                <img alt={fileTitleValue} title={fileTitleValue} src={srcValue} className={ImageFieldClasses.image} />
              </li>
            );
          })}
        </ul>
      </Root>
    );
  }

  const titleValue = get(record, title) || title;

  return (
    <Root className={className} {...sanitizeFieldRestProps(rest)}>
      <img title={titleValue} alt={titleValue} src={getFileUrl(sourceValue)} className={ImageFieldClasses.image} />
    </Root>
  );
};
