import * as React from 'react';
import { useMemo } from 'react';
import { Edit, Form, FormInputValue, IFormInput } from '../../components/molucules/custom-react-admin';
import { UnsettledBalanceHistoryInputs } from './show';
import { CommonButton } from '../../components/atoms/button/common-button';
import { Box } from '@mui/material';
import { FormGroup } from '../../components/atoms/form';
import { useNotify, useRedirect, useResourceContext } from 'react-admin';
import { useParams } from 'react-router-dom';

export const UnsettledBalanceHistoriesEdit = () => {
  const formInputs: IFormInput[] = useMemo(() => {
    return UnsettledBalanceHistoryInputs.map((input) => {
      // only revenue editable
      if (input.name === 'revenue') {
        return {
          label: input.label,
          name: input.name,
          type: 'text',
          inputProps: {
            type: 'number',
          },
          validation: {
            required: input.label + 'は数値のみ入力可能です',
          },
        };
      }

      // all input is text
      return {
        label: input.label,
        name: input.name,
        type: 'custom',
        render: (record: any) => (
          <Box>
            <FormGroup label={input.label}>
              <FormInputValue {...input} record={record} />
            </FormGroup>
          </Box>
        ),
      };
    });
  }, []);

  const notify = useNotify();
  const redirect = useRedirect();
  const { id: routeId } = useParams<'id'>();
  const resource = useResourceContext();

  const onSaveSuccess = (values: any) => {
    notify(routeId ? '更新しました' : '作成した');
    redirect('show', resource, routeId);
  };

  return (
    <Edit
      recordTitleAttribute={'id'}
      buttons={
        <CommonButton
          text={'戻る'}
          type={'button'}
          variant={'green'}
          onClick={() => redirect('show', resource, routeId)}
        />
      }
    >
      <Box sx={{ '& .VTextField': { width: '150px' }, '& .formGroup': { marginTop: '5px' } }}>
        <Form
          data={formInputs}
          renderButtonSubmit={(record) => {
            if (!record) return <></>;

            return <CommonButton text={'収益を編集する'} type={'submit'} variant={'black'} />;
          }}
          renderButtonBack={(record) => <></>}
          onSuccess={onSaveSuccess}
        />
      </Box>
    </Edit>
  );
};
