// import {
//   DateTimeInput,
//   RadioButtonGroupInput,
//   SimpleForm,
//   TextInput,
//   SelectInput,
//   SaveButton,
//   type ValidateForm,
// } from 'react-admin';
import { Create, Form, IFormInput } from '../../components/molucules/custom-react-admin';
import { NoteCategory, NoteOpenStatus } from '../../interface/note';
import { NOTE_CATEGORY_FIELD_LABEL, NOTE_OPEN_STATUS_FIELD_LABEL } from '../../util/constant/label';
import * as React from 'react';

export const NoteFormData: IFormInput[] = [
  {
    name: 'category',
    label: 'カテゴリ',
    type: 'select',
    options: [
      {
        id: NOTE_CATEGORY_FIELD_LABEL[NoteCategory.news],
        label: NOTE_CATEGORY_FIELD_LABEL[NoteCategory.news],
      },
      {
        id: NOTE_CATEGORY_FIELD_LABEL[NoteCategory.feature],
        label: NOTE_CATEGORY_FIELD_LABEL[NoteCategory.feature],
      },
    ],
    required: true,
  },
  {
    name: 'title',
    label: 'タイトル',
    required: true,
  },
  {
    name: 'thumbnailImageUrl',
    label: 'サムネイル画像',
    type: 'file',
    required: true,
    inputProps: {
      errorLabel: 'サムネイル画像',
    },
  },
  {
    name: 'body',
    label: '本文',
    type: 'richTextEditor',
    required: true,
  },
  {
    name: 'openStatus',
    label: '公開ステータス',
    type: 'radio',
    options: [
      {
        id: NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.open],
        label: NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.open],
      },
      {
        id: NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.close],
        label: NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.close],
      },
    ],
    required: true,
  },
  {
    name: 'openedAt',
    label: '公開日時',
    type: 'datetime',
    required: true,
  },
];

const NoteCreate = () => {
  // const validateForm: ValidateForm = (fieldValues) => {
  //   const errors: ValidateFormError = {};
  //   if (fieldValues.openStatus === NoteOpenStatus.open && !fieldValues.openedAt) {
  //     errors.openedAt = `公開ステータスで「${
  //       NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.open]
  //     }」を選択している場合は入力してください`;
  //   }
  //   return errors;
  // };

  return (
    <Create title="コンテンツ新規作成">
      {/*<SimpleForm
        toolbar={
          <>
            <SaveButton label="保存" icon={<></>} />
          </>
        }
        defaultValues={{
          category: NoteCategory.news,
          openStatus: NoteOpenStatus.close,
        }}
        validate={validateForm}
      >
        <Stack spacing={2} width="100%">
          <SelectInput
            required
            variant="outlined"
            name="category"
            source="category"
            label="カテゴリ"
            emptyText="– 選択してください –"
            choices={[
              {
                id: NoteCategory.news,
                name: NOTE_CATEGORY_FIELD_LABEL[NoteCategory.news],
              },
              {
                id: NoteCategory.feature,
                name: NOTE_CATEGORY_FIELD_LABEL[NoteCategory.feature],
              },
            ]}
            helperText={false}
          />
          <TextInput required name="title" source="title" label="タイトル" variant="outlined" helperText={false} />
          <ImageFileInput
            label="サムネイル画像"
            name="thumbnailImageUrl"
            source="thumbnailImageUrl"
            helperText={false}
            dropzoneLabel={
              <Typography fontSize="0.75rem" color="rgba(0, 0, 0, 0.6)" textAlign="center">
                画像ファイルをアップロード (PNG・JPEG)
              </Typography>
            }
            accept={[ImageFileMIME.png, ImageFileMIME.jpeg]}
          />
          <RichTextEditorInput isRequired name="body" source="body" label="本文" helperText={false} />
          <RadioButtonGroupInput
            label="公開ステータス"
            name="openStatus"
            source="openStatus"
            choices={[
              {
                id: NoteOpenStatus.open,
                name: NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.open],
              },
              {
                id: NoteOpenStatus.close,
                name: NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.close],
              },
            ]}
            helperText={false}
          />
          <DateTimeInput
            name="openedAt"
            source="openedAt"
            label="公開日時"
            variant="outlined"
            helperText={
              <strong>
                公開ステータスで「{NOTE_OPEN_STATUS_FIELD_LABEL[NoteOpenStatus.open]}
                」を選択している場合は入力してください。
              </strong>
            }
          />
        </Stack>
      </SimpleForm>*/}

      <Form data={NoteFormData} />
    </Create>
  );
};

export default NoteCreate;
