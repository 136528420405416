import * as React from 'react';
import { ReactNode, useState } from 'react';
import { FilterFormInputProps, List } from '../../components/molucules/custom-react-admin';
import {
  Datagrid,
  DateField,
  FunctionField,
  ShowButton,
  TextField,
  useNotify,
  useRefresh,
  WrapperField,
} from 'react-admin';
import { CustomLink } from '../../components/atoms/custom-link';
import { CommonButton } from '../../components/atoms/button/common-button';
import { Box, InputLabel } from '@mui/material';
import { LABEL_STYLE, LABEL_WIDTH } from '../../util/constant/form';
import { VTextField } from '../../components/atoms/form';
import { axiosBackendClient } from '../../util/axios';
import { ModalCancel } from '../../components/molucules/modal/modal-cancel';

const RENTAL_PLAN_OPTIONS: { id: string; label: string }[] = [
  { id: 'プレミアムプラン', label: 'プレミアム' },
  { id: 'レギュラープラン', label: 'レギュラー' },
];

const ACTION_OPTIONS: { id: string; label: string }[] = [
  { id: 'RENTAL', label: 'RENTAL' },
  { id: 'EXCHANGE', label: 'EXCHANGE' },
  { id: 'EXCHANGE_RETURN', label: 'EXCHANGE_RETURN' },
  { id: 'CANCEL_RETURN', label: 'CANCEL_RETURN' },
  { id: 'PURCHASE', label: 'PURCHASE' },
];

const PriceFilter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .vPriceLabel': {
          width: '100%',
          maxWidth: LABEL_WIDTH,
        },
        '& .vPriceSpacing': {
          marginLeft: '10px',
        },
        '& .vPriceInput': {
          maxWidth: '40%',
        },
      }}
    >
      <Box className={'vPriceLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>単価</InputLabel>
      </Box>
      <Box className={'vPriceInput'}>
        <VTextField name={'priceFrom'} type={'number'} preventDot preventNegativeNumber />
      </Box>
      <Box className={'vPriceSpacing'}>~</Box>
      <Box className={'vPriceSpacing vPriceInput'}>
        <VTextField name={'priceTo'} type={'number'} preventDot preventNegativeNumber />
      </Box>
    </Box>
  );
};

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: '注文ID', name: 'id' },
  { type: 'input', label: '注文者名', name: 'renterNickname' },
  { type: 'render', label: '単価', name: 'price', render: () => <PriceFilter /> },
  { type: 'input', label: '作品ID', name: 'artworkId' },
  { type: 'datetime', label: '受付日', name: 'createdAt' },
  { type: 'select', label: '状態', name: 'action', options: ACTION_OPTIONS },
  { type: 'select', label: 'プラン', name: 'rentalPlan', options: RENTAL_PLAN_OPTIONS },
];

export const RentalList = (props: { children?: ReactNode }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [isCallApi, setIsCallApi] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState<string>('');
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelRentalClick = (id: any) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCancelRental = async () => {
    if (isCallApi) {
      return;
    }
    setIsCallApi(true);

    try {
      await axiosBackendClient.post<ResponseType>(
        '/rentals/cancel/' + id,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      refresh();
      notify('キャンセル許諾完了');
    } catch (e) {
      // @ts-ignore
      notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
    }

    setIsCallApi(false);
    setOpenModal(false);
  };

  return (
    <List {...props} title={'レンタル管理'} hasCreate={false} filters={filterForm}>
      <Datagrid bulkActionButtons={false} optimized>
        <TextField source="id" label="注文ID" sortable={true} />
        <FunctionField
          label="注文者名（サイト名）"
          render={(record: any) => {
            return (
              <CustomLink resource={'accounts'} type={'show'} id={record.renterId} title={record.renterNickname} />
            );
          }}
          sortable={true}
          sortBy={'renterNickname'}
        />
        <TextField source="deliveryNickname" label="注文者名（送付先名）" sortable={true} sortBy={'deliveryNickname'} />

        <FunctionField
          label="作品名"
          render={(record: any) => {
            return <CustomLink resource={'artworks'} type={'show'} id={record.artworkId} title={record.artworkTitle} />;
          }}
          sortable={true}
          sortBy={'artworkTitle'}
        />
        <TextField source="rentalPlan" label="プラン" sortable={true} sortBy={'rentalPlan'} />
        <DateField source="createdAt" label="注文日" sortable={true} showTime locales={'ja-jp'} sortBy={'created_at'} />
        <TextField source="action" label="状態" sortable={true} sortBy={'action'} />
        <FunctionField
          label="キャンセル状態"
          render={(record: any) => {
            return record.subscriptionCanceled ? 'キャンセル' : '';
          }}
          sortable={false}
        />
        <WrapperField label={'操作'}>
          <FunctionField
            label="Name"
            render={(record: any) => (
              <CommonButton
                text={'キャンセル許諾'}
                type={'button'}
                className={'buttonNoIcon'}
                variant={'none'}
                onClick={() => handleCancelRentalClick(record.id)}
              />
            )}
          />
          <ShowButton className={'buttonNoIcon'} label={'表示'} />
        </WrapperField>
      </Datagrid>
      <ModalCancel id={id} open={openModal} onClose={handleCloseModal} onSubmit={handleCancelRental} />
    </List>
  );
};
