import React from 'react';
import { Admin, CustomRoutes, Resource, memoryStore } from 'react-admin';
import { MyLayout } from './components/templates/layout';
import { lightTheme } from './services/theme';
import { authProvider } from './providers/auth-provider';
import Login from './pages/auth/login';
import artworks from './pages/artworks';
import artists from './pages/artists';
import { createBrowserHistory as createHistory } from 'history';
import { dataProvider } from './providers/data-provider';
import artistApplicants from './pages/artist-applicants';
import accounts from './pages/accounts';
import purchaseHistories from './pages/purchase-history';
// import deliveries from './pages/delivery';
import notes from './pages/notes';
import chats from './pages/chats';
import rentals from './pages/rentals';
import balanceHistories from './pages/balance-histories';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import japaneseMessages from './locale/ja';
import { Route } from 'react-router-dom';
import { BalanceHistoryAccount } from './pages/balance-histories/account';
import unsettledBalanceHistories from './pages/unsettled-balance-histories';

const i18nProvider = polyglotI18nProvider(() => japaneseMessages, 'ja');

const App: React.FC = () => {
  // TIPS:
  // <Resource name="samples" list={ListGuesser} />
  // のように、ListGuesserを利用してapiの返り値を元にブラウザのコンソールにコンポーネントの雛形を出力できる。
  // 参考: https://dev.classmethod.jp/articles/react-admin-tutorial/#toc-5
  const history = createHistory();
  return (
    <Admin
      i18nProvider={i18nProvider}
      requireAuth={true}
      theme={lightTheme}
      layout={MyLayout}
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider}
      // dashboard={MyDashBoard}
      history={history}
      store={memoryStore()}
    >
      {/*<Resource name="words" list={WordList} edit={WordEdit} />*/}
      <Resource name="artists" {...artists} />
      <Resource name="artist-applicants" {...artistApplicants} />
      <Resource name="accounts" {...accounts} />
      <Resource name="artworks" {...artworks} />
      <Resource name="chats" {...chats} />
      {/*<Resource name="purchases" {...purchases} />*/}
      <Resource name="notes" {...notes} />
      <Resource name="purchase-histories" {...purchaseHistories} />
      <Resource name="rentals" {...rentals} />
      <Resource name="balance-histories" {...balanceHistories} />
      {/*<Resource name="deliveries" {...deliveries} />*/}
      <CustomRoutes>
        <Route path="/balance-histories/account/:id" element={<BalanceHistoryAccount />} />
      </CustomRoutes>
      <Resource name="unsettled-balance-histories" {...unsettledBalanceHistories} />
    </Admin>
  );
};

export default App;
