import { Datagrid, TextField, FunctionField, ShowButton, WrapperField, DateField, EditButton } from 'react-admin';
import { FilterFormInputProps, List } from 'src/components/molucules/custom-react-admin';
import type { ComponentWithChildren } from 'src/types/component';
import { type INote } from 'src/interface/note';
import { NOTE_CATEGORY_FIELD_LABEL, NOTE_OPEN_STATUS_FIELD_LABEL } from 'src/util/constant/label';

const CATEGORY_OPTIONS: { id: string; label: string }[] = [
  { id: 'お知らせ', label: 'お知らせ' },
  { id: 'コンテンツ', label: 'コンテンツ' },
];

const filterForm: FilterFormInputProps[] = [
  { type: 'select', label: 'カテゴリ', name: 'category', options: CATEGORY_OPTIONS },
];

const NoteList: ComponentWithChildren = (props) => {
  return (
    <List {...props} title={'コンテンツ(お知らせ・コンテンツ)リスト'} hasCreate filters={filterForm}>
      <Datagrid bulkActionButtons={false} optimized>
        <FunctionField
          label="カテゴリ"
          render={(record: INote) => NOTE_CATEGORY_FIELD_LABEL[record.category]}
          sortable={true}
          sortBy={'category'}
        />
        <TextField source="title" label="タイトル" sortable={true} sortBy={'title'} />
        <FunctionField
          label="公開ステータス"
          render={(record: INote) => NOTE_OPEN_STATUS_FIELD_LABEL[record.openStatus]}
          sortable={true}
          sortBy={'open_status'}
        />
        <DateField label="公開日時" source="openedAt" showTime sortable={true} sortBy={'opened_at'} />
        <WrapperField label="操作">
          <ShowButton className={'buttonNoIcon'} label="表示" />
          <EditButton className={'buttonNoIcon'} label="編集" />
        </WrapperField>
      </Datagrid>
    </List>
  );
};

export default NoteList;
