import { Box, Card, InputLabel } from '@mui/material';
import { LABEL_STYLE, LABEL_WIDTH } from '../../../util/constant/form';
import * as React from 'react';
import { useVForm, VDatePicker, VFormContainer } from '../../atoms/form';
import { CommonButton } from '../../atoms/button/common-button';
import { useFormContext, useWatch } from 'react-hook-form';

type ExportCsvFormProps = {
  apiUrl: string;
  formInputs?: React.ReactNode;
};

export const ExportCsvForm = ({ apiUrl, formInputs = <DefaultFormInput /> }: ExportCsvFormProps) => {
  const formContext = useVForm({
    defaultValues: {},
  });

  const handleExportCsv = (data: any) => {
    const filter = { createdAtFrom: data.createdAtFrom, createdAtTo: data.createdAtTo };
    const url = apiUrl + '?filter=' + encodeURIComponent(JSON.stringify(filter));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', url);
    // link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleResetForm = () => {
    formContext.reset({});
  };

  return (
    <Card sx={styles}>
      <Box className={'exportFormTitle'}>csv出力</Box>
      <Box className={'exportFormBody'}>
        <VFormContainer formContext={formContext} onSuccess={handleExportCsv}>
          {formInputs}
          <Box className={'exportFormButton'}>
            <CommonButton text={'csv出力'} type={'submit'} />
            <CommonButton
              text={'リセット'}
              type={'button'}
              variant={'white'}
              className={'exportFormButtonReset'}
              onClick={handleResetForm}
            />
          </Box>
        </VFormContainer>
      </Box>
    </Card>
  );
};

const DefaultFormInput = () => {
  const formContext = useFormContext();
  const createAtToValue = useWatch({
    // @ts-ignore
    name: 'createdAtTo',
    control: formContext.control,
    defaultValue: '',
  });

  const dateFromTo = (value: string | undefined) => {
    if (value && createAtToValue) {
      return value < createAtToValue ? true : '登録日Fromは登録日To以前の日付にしてください。';
    }
    return true;
  };

  return (
    <Box className={'vDatePicker'}>
      <Box className={'vDatePickerLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>登録日</InputLabel>
      </Box>
      <Box className={'vDatePickerInput'}>
        <VDatePicker name={'createdAtFrom'} validation={{ validate: dateFromTo }} />
      </Box>
      <Box className={'vDatePickerSpacing'}>~</Box>
      <Box className={'vDatePickerSpacing vDatePickerInput'}>
        <VDatePicker name={'createdAtTo'} />
      </Box>
    </Box>
  );
};

const styles = {
  margin: '16px 0',
  borderRadius: '5px',
  '& .exportFormTitle': {
    padding: '16px',
    background: '#F5F5F5',
  },
  '& .exportFormBody': {
    padding: '16px 0',
    '& .exportFormRow': {
      padding: '16px',
    },
  },

  '& .vDatePicker': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .vDatePickerLabel': {
    width: '100%',
    maxWidth: LABEL_WIDTH,
  },
  '& .vDatePickerSpacing': {
    marginLeft: '10px',
  },
  '& .vDatePickerInput': {
    maxWidth: '40%',
  },
  '& .exportFormButton': {
    padding: '16px 0',
    paddingLeft: {
      xs: '0',
      sm: '200px',
    },

    '& .exportFormButtonReset': {
      marginLeft: '10px',
    },
  },
};
