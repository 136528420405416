import { ListItemIcon } from '@mui/material';
import { BalanceHistoryList } from './list';
import { BalanceHistoryShow } from './show';

export default {
  menuIcon: ListItemIcon,
  options: { label: '収益金管理' },
  list: BalanceHistoryList,
  show: BalanceHistoryShow,
};
