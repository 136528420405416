import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { className } from '../../../../util/function';
import { LABEL_WIDTH } from '../../../../util/constant/form';

type Props = {
  children: ReactNode;
  row?: boolean;
};
const styles = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  justifyContent: { xs: 'center', sm: 'start' },
  paddingLeft: { xs: 0, sm: LABEL_WIDTH },
  alignItems: 'center',
  '&.row': {
    flexDirection: 'row',
  },
};

export const FormGroupButton = ({ children, row = true }: Props): JSX.Element => {
  return (
    <Box sx={styles} className={className({ row: !!row })}>
      {children}
    </Box>
  );
};
