import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';
import { EditButton, ShowBase, Title, useShowContext } from 'react-admin';
import { BackButton } from './back-button';
// import { MutationMode } from 'ra-core/src/types';
// import { UseGetOneHookValue } from 'ra-core/src/dataProvider/index';
// import { UseUpdateMutateParams } from 'ra-core/src/dataProvider/index';

type ShowBaseProps = {
  defaultTitle?: string;
  // Necessary for actions (EditActions) which expect a data prop containing the record
  // @deprecated - to be removed in 4.0d
  // data?: RecordType;
  // error?: any;
  // isFetching: boolean;
  // isLoading: boolean;
  resource?: string;
  // record?: RecordType;
  // refetch: UseGetOneHookValue<RecordType>['refetch'];
};

type ShowBodyProps = {
  children?: ReactNode;
  hasEdit?: boolean;
  hasBack?: boolean;
  recordTitleAttribute?: string;
  title?: string;
  buttons?: ReactNode;
};

type Props = ShowBaseProps & ShowBodyProps;

const ShowBody = ({ hasBack, hasEdit, children, recordTitleAttribute, title, buttons }: ShowBodyProps): JSX.Element => {
  const { defaultTitle, isLoading, record, resource } = useShowContext();

  if (isLoading) return <></>;

  const getTitle = () => {
    if (title) {
      return title;
    }

    if (record && recordTitleAttribute && record[recordTitleAttribute]) {
      return record[recordTitleAttribute];
    }

    return defaultTitle;
  };
  const innerTitle = getTitle();

  return (
    <Card className={'showContainer'}>
      <Title title={innerTitle} />
      <Typography variant={'h6'} className={'showTitle'}>
        {innerTitle}
        <Box className={'showTitleButton'}>
          {buttons}
          {hasBack && <BackButton resource={resource} variant={'green'} className={'ml-10'} label={'戻る'} />}
          {hasEdit && <EditButton label={'編集'} className={'showButtonCreate buttonNoIcon ml-10'} />}
        </Box>
      </Typography>
      <Box className={'show'}>{children}</Box>
    </Card>
  );
};

export const Show = ({
  children,
  hasEdit = false,
  hasBack = true,
  recordTitleAttribute,
  title,
  buttons,
  ...props
}: Props) => {
  return (
    <Box sx={styles}>
      <ShowBase {...props}>
        <ShowBody
          hasEdit={hasEdit}
          hasBack={hasBack}
          recordTitleAttribute={recordTitleAttribute}
          title={title}
          buttons={buttons}
        >
          {children}
        </ShowBody>
      </ShowBase>
    </Box>
  );
};

const styles = {
  padding: '50px 25px',
  '& .showContainer': {
    padding: '20px',
    paddingBottom: '50px',
  },
  '& .MuiCard-root': {
    borderRadius: 0,
  },
  '& .showTitle': {
    paddingBottom: '10px',
    borderBottom: '1px solid #E6E9ED',
    color: '#73879D;',
    '& .showTitleButton': {
      display: 'inline-block',
      float: 'right',
      '& .showButtonCreate': {
        background: '#5CB85C',
        borderColor: `#5CB85C !important`,
        color: '#FFF',
        width: 'auto',
        borderRadius: '6px',
        boxShadow: 'none',
        border: '1px solid',
        padding: '6px 10px',
        minWidth: '50px',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        '&:hover': {
          background: '#5CB85C',
          borderColor: `#5CB85C !important`,
          color: '#FFF',
          opacity: 0.9,
        },
      },
    },
  },

  '& .show': {
    marginTop: '20px',
    '& .formGridLabel': {
      '& .MuiInputLabel-root': {
        textAlign: 'left',
      },
    },
  },

  '& .buttonNoIcon': {
    '& .MuiButton-startIcon': {
      display: 'none',
    },
  },

  '& .mr-10': {
    marginRight: '10px',
  },
  '& .ml-10': {
    marginLeft: '10px',
  },
  a: {
    textDecoration: 'none',
    color: '#3954FF',
  },
};
