import * as React from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { useMemo } from 'react';
import { IMessage } from '../../interface/chat';
import format from 'date-fns/format';

const attrs: IFormInputValue[] = [
  { label: '作品ID', name: 'artworkId' },
  { label: '作品名', name: 'artworkTitle' },
  { label: '問い合わせ日時', name: 'createdAt', type: 'datetime' },
  { label: '問い合わせた人', name: 'inquirerNickname' },
  {
    label: '問い合わせを受けた人',
    name: 'ownerNickname',
    render: (record) => record.ownerArtistName ?? record.ownerNickname,
  },
];

export const ChatShow = () => {
  return (
    <Show title={'問い合わせ詳細'} hasEdit={false}>
      <RecordInfo data={attrs} />
      <Box
        sx={{
          color: '#73879D',
          fontWeight: '600',
          margin: '30px 0',
          fontSize: '20px',
        }}
      >
        チャット詳細
      </Box>
      <ChatDetail />
    </Show>
  );
};

const ChatDetail = () => {
  const record = useRecordContext();
  const DAY_OF_WEEK = ['日', '月', '火', '水', '木', '金', '土'];

  const ownerId = useMemo(() => {
    return record.ownerId;
  }, [record]);

  const messageList: IMessage[] = useMemo(() => {
    return record?.chatDetailList;
  }, [record]);

  const sortMessages = useMemo(() => {
    const items =
      messageList?.reduce((newMessages: CustomMessageList, item: IMessage) => {
        const _sendDate = new Date(item.sendDate);
        const dayOfWeek = DAY_OF_WEEK[_sendDate.getDay()] || '';
        const sendDate = format(_sendDate, 'M月d日 ' + (dayOfWeek ? '(' + dayOfWeek + ')' : ''));
        newMessages[sendDate] = newMessages[sendDate] || [];
        newMessages[sendDate].push(item);

        return newMessages;
      }, {}) ?? {};

    const sortItems = Object.keys(items)
      .sort()
      .reduce((obj: CustomMessageList, key) => {
        // sort message by createdAt and id
        items[key].sort((a: IMessage, b: IMessage) => {
          const $a = a.sendDate;
          const $b = b.sendDate;
          if ($a > $b) {
            return 1;
          }
          if ($a < $b) {
            return -1;
          }
          return 0;
        });

        obj[key] = items[key];

        return obj;
      }, {});

    return sortItems;
    // eslint-disable-next-line
  }, [messageList]);

  const RenderMessages = (): JSX.Element => {
    return (
      <>
        {Object.keys(sortMessages).map((day) => {
          const messages = sortMessages[day];
          return (
            <React.Fragment key={day}>
              <Typography variant="body1" align={'center'}>
                {day}
              </Typography>
              {messages.map((message, index) => {
                return <MessageChat message={message} key={index} ownerId={ownerId} />;
              })}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <Box
      sx={{
        background: '#F0F0F0',
        height: 'max(500px, 50vh)',
        padding: '25px',
        overflowY: 'scroll',
      }}
    >
      <RenderMessages />
    </Box>
  );
};

interface CustomMessageList {
  [key: string]: IMessage[];
}

const MessageBubble = ({ text, variant = 'self' }: { text: string; variant: 'self' | 'target' }): JSX.Element => {
  const style = {
    borderRadius: '10px !important',
    boxShadow: 'none',
    background: variant === 'self' ? '#FFF' : '#7C7C7C',
    color: variant === 'self' ? '#000' : '#FFF',
  };

  return (
    <Card sx={style}>
      <CardContent
        sx={{
          padding: '10px 18px',
          '&:last-child': {
            paddingBottom: '10px',
          },
        }}
      >
        <Typography variant="body2" sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

const LeftMessage = ({ text, time }: { text: string; time: string }): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Box sx={{ position: 'relative', paddingRight: '45px', maxWidth: { xs: '70%', sm: 'calc(50% - 45px)' } }}>
        <Typography variant="caption" sx={{ position: 'absolute', right: 0, bottom: 0, color: '#999' }}>
          {time}
        </Typography>
        <MessageBubble text={text} variant={'target'} />
      </Box>
    </Box>
  );
};

const RightMessage = ({
  text,
  time,
  isAlreadyRead,
}: {
  text: string;
  time: string;
  isAlreadyRead: boolean;
}): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Box
        sx={{
          position: 'relative',
          paddingLeft: '45px',
          maxWidth: { xs: '70%', sm: 'calc(50% - 45px)' },
          marginLeft: '8px',
        }}
      >
        {isAlreadyRead && (
          <Typography variant="caption" sx={{ position: 'absolute', left: '5px', bottom: '18px', color: '#999' }}>
            既読
          </Typography>
        )}
        <Typography variant="caption" sx={{ position: 'absolute', left: 0, bottom: 0, color: '#999' }}>
          {time}
        </Typography>

        <MessageBubble text={text} variant={'self'} />
      </Box>
    </Box>
  );
};

const MessageChat = ({ message, ownerId }: { message: IMessage; ownerId: string }): JSX.Element => {
  const _sendDate = new Date(message.sendDate);
  return (
    <Box sx={{ padding: '10px 0' }}>
      {message.from !== ownerId ? (
        <LeftMessage text={message.text} time={format(_sendDate, 'HH:mm')} />
      ) : (
        <RightMessage text={message.text} time={format(_sendDate, 'HH:mm')} isAlreadyRead={message.isAlreadyRead} />
      )}
    </Box>
  );
};
