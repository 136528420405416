export const FORM_WIDTH = '670px';

export const LABEL_WIDTH = '200px';

export const INPUT_STYLE = {
  margin: 0,
  '&.VSelect': {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiSelect-select': {
        padding: '7px 14px',
        paddingRight: '32px',
      },
    },
  },
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    minWidth: 'min(100%, 350px)',
    padding: '7px 14px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ccc',
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiFormHelperText-root': { margin: '3px 0 0' },
  '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button': {
    appearance: 'none',
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export const LABEL_STYLE = {
  paddingTop: '11px',
  color: '#73879D',
  whiteSpace: 'inherit',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '16px',
  paddingRight: '20px',
  textAlign: 'right',
};

export const FORM_GRID = {
  width: '100%',
  '& .formGridLabel': {
    width: '100%',
    maxWidth: { xs: '100%', sm: LABEL_WIDTH },
  },
  '& .formGridInput': {
    width: { xs: '100%', sm: '100%' },
    marginTop: { xs: '10px', sm: 0 },
  },
  '&.hasLabel': {
    '& > .formGridInput': {
      width: { sm: `calc(100% - ${LABEL_WIDTH})` },
    },
  },
};
