import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';
import { EditBase, Title, useEditContext } from 'react-admin';
import { RaRecord, RedirectionSideEffect, TransformData } from 'ra-core';
import { UseQueryOptions } from 'react-query';
import { BackButton } from './back-button';
// import { UseUpdateMutateParams } from 'ra-core/src/dataProvider/index';
// import { MutationMode } from 'ra-core/src/types';

type EditBaseProps<RecordType extends RaRecord = any> = {
  disableAuthentication?: boolean;
  id?: RecordType['id'];
  // mutationMode?: MutationMode;
  // mutationOptions?: UseMutationOptions<RecordType, MutationOptionsError, UseUpdateMutateParams<RecordType>> & {
  //   meta?: any;
  // };
  queryOptions?: UseQueryOptions<RecordType> & { meta?: any };
  redirect?: RedirectionSideEffect;
  resource?: string;
  transform?: TransformData;
  buttons?: React.ReactNode;
  [key: string]: any;
};

type Props = EditBaseProps & {
  children?: ReactNode;
  recordTitleAttribute?: string;
  defaultTitle?: string;
};

const styles = {
  padding: '50px 25px',
  '& .editContainer': {
    padding: '20px',
    paddingBottom: '50px',
  },
  '& .MuiCard-root': {
    borderRadius: 0,
  },
  '& .editTitle': {
    paddingBottom: '10px',
    borderBottom: '1px solid #E6E9ED',
    color: '#73879D;',
  },

  '& .edit': {
    marginTop: '20px',
    '& .formGridLabel': {
      '& .MuiInputLabel-root': {
        textAlign: 'left',
      },
    },
  },
  '& .editTitleButton': {
    display: 'inline-block',
    float: 'right',
  },
};

const EditChild = ({
  children,
  recordTitleAttribute,
  defaultTitle,
  buttons,
}: {
  children?: ReactNode;
  recordTitleAttribute?: string;
  defaultTitle?: string;
  buttons?: React.ReactNode;
}) => {
  const { defaultTitle: RADefaultTitle, isLoading, record, resource } = useEditContext();

  if (isLoading) return <></>;

  const getTitle = () => {
    if (record && recordTitleAttribute && record[recordTitleAttribute]) {
      return record[recordTitleAttribute];
    }
    if (defaultTitle) return defaultTitle;
    return RADefaultTitle;
  };
  const title = getTitle();

  return (
    <>
      <Card className={'editContainer'}>
        <Title title={title} />
        <Typography variant={'h6'} className={'editTitle'}>
          {title}
          <Box className={'editTitleButton'}>{buttons}</Box>
        </Typography>
        <Box className={'edit'}>{children}</Box>
      </Card>
    </>
  );
};

export const Edit = ({ children, recordTitleAttribute, defaultTitle, buttons, ...props }: Props) => {
  return (
    <Box sx={styles}>
      <EditBase {...props} mutationMode={'pessimistic'}>
        <EditChild recordTitleAttribute={recordTitleAttribute} defaultTitle={defaultTitle} buttons={buttons}>
          {children}
        </EditChild>
      </EditBase>
    </Box>
  );
};
