import React, { ChangeEvent } from 'react';
import { Control, FieldError, useController } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import RadioIcon from '../../icon/radio';
import RadioCheckedIcon from '../../icon/radio-checked';
import { FORM_GRID, LABEL_STYLE } from '../../../../util/constant/form';
import RequiredLabel from '../required-label';
import { className } from '../../../../util/function';
import errorMessage from '../common/message';

export type VRadioProps = {
  options: any[];
  helperText?: string;
  helperLink?: string;
  name: string;
  required?: boolean;
  parseError?: (error: FieldError) => string;
  label?: string;
  labelKey?: string;
  valueKey?: string;
  type?: 'number' | 'string';
  emptyOptionLabel?: 'string';
  onChange?: (value: any) => void;
  returnObject?: boolean;
  row?: boolean;
  control?: Control<any>;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  showRequiredText?: boolean;
};

export default function VRadio({
  helperText,
  helperLink,
  options,
  label,
  name,
  parseError,
  labelKey = 'label',
  valueKey = 'id',
  required,
  emptyOptionLabel,
  returnObject,
  row,
  control,
  showRequiredText = true,
  ...rest
}: VRadioProps): JSX.Element {
  const theme = useTheme();
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    rules: required ? { required: errorMessage('required', label || 'This field') } : undefined,
    control,
  });

  const onRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const radioValue = (event.target as HTMLInputElement).value;
    const returnValue = returnObject ? options.find((items) => items[valueKey] === radioValue) : radioValue;
    // setValue(name, returnValue, { shouldValidate: true })
    onChange(returnValue);
    if (typeof rest.onChange === 'function') {
      rest.onChange(returnValue);
    }
  };

  const DefaultIcon = () => {
    return <RadioIcon border={invalid ? theme.palette.error.main : '#999'} />;
  };

  const DefaultCheckedIcon = () => {
    return <RadioCheckedIcon />;
  };
  const radioStyles = {
    color: invalid ? theme.palette.error.main : undefined,
    alignSelf: 'flex-start',
    '& .MuiSvgIcon-root': {
      borderColor: invalid ? theme.palette.error.main : '#999',
    },
    '&.Mui-checked': {
      color: '#000',
    },
    '& svg': {
      width: '25px',
      height: '25px',
    },
  };

  return (
    <FormControl error={invalid}>
      <Grid container className={className({ hasLabel: !!label })} sx={FORM_GRID}>
        {label && (
          <Grid item className={'formGridLabel'} sx={{ paddingTop: { xs: '0', sm: '10px' } }}>
            <FormLabel error={invalid} sx={LABEL_STYLE}>
              {label} {required && showRequiredText ? <RequiredLabel /> : ''}
            </FormLabel>
          </Grid>
        )}
        <Grid item className={'formGridInput'}>
          <RadioGroup onChange={onRadioChange} name={name} row={row} value={value || ''}>
            {emptyOptionLabel && (
              <FormControlLabel
                control={
                  <Radio
                    icon={rest.icon ? rest.icon : <DefaultIcon />}
                    checkedIcon={rest.checkedIcon ? rest.checkedIcon : <DefaultCheckedIcon />}
                    sx={radioStyles}
                    checked={!value}
                  />
                }
                label={emptyOptionLabel}
                value=""
              />
            )}
            {options.map((option: any) => {
              const optionKey = option[valueKey];
              if (!optionKey) {
                console.error(`CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`, option);
              }

              const isChecked = !!(value && (returnObject ? value[valueKey] === optionKey : value === optionKey));

              return (
                <FormControlLabel
                  control={
                    <Radio
                      icon={rest.icon ?? (option.icon ? option.icon(invalid) : undefined) ?? <DefaultIcon />}
                      checkedIcon={
                        rest.checkedIcon ??
                        (option.checkedIcon ? option.checkedIcon() : undefined) ?? <DefaultCheckedIcon />
                      }
                      sx={radioStyles}
                      checked={isChecked}
                    />
                  }
                  value={optionKey}
                  label={option[labelKey]}
                  key={optionKey}
                />
              );
            })}
          </RadioGroup>
          {helperText && (
            <FormHelperText
              onClick={helperLink ? () => window.open(helperLink) : undefined}
              sx={{
                margin: '3px 0 0',
                textDecoration: helperLink ? 'underline' : 'none',
                cursor: helperLink ? 'pointer' : 'default',
              }}
            >
              {helperText}
            </FormHelperText>
          )}
          {error && (
            <FormHelperText error={invalid} sx={{ margin: '3px 0 0' }}>
              {typeof parseError === 'function' ? parseError(error) : error.message}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </FormControl>
  );
}
