import React from 'react';
import { Box } from '@mui/material';

const RequiredLabel = () => {
  return (
    <Box component={'span'} sx={{ paddingLeft: 1, color: 'error.main' }}>
      *
    </Box>
  );
};

export default RequiredLabel;
