import { fetchUtils } from 'react-admin';
import { DataProvider } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';

interface IFetchJson {
  status: number;
  headers: Headers;
  body: string;
  json: any;
}

export const httpClient = (url: string, options: fetchUtils.Options = {}): Promise<IFetchJson> => {
  const authParams = localStorage?.getItem('auth') || '';
  const { access_token } = JSON.parse(authParams);
  if (!access_token) throw new Error('ログインしてください');
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  // if (options.method?.toUpperCase() === 'PUT') {
  //   options.method = 'POST';
  // }

  options.user = {
    authenticated: true,
    token: access_token,
  };

  return fetchUtils.fetchJson(url, options);
};

// データを取得するクライアントとエンドポイントのベースURLの初期化
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || '';
const defaultDataProvider: DataProvider = simpleRestProvider(BASE_API_URL, httpClient);

export const dataProvider: DataProvider = {
  ...defaultDataProvider,
  getList: (resource, params) => {
    return defaultDataProvider.getList(resource, params);
  },
  getOne: async (resource, params) => {
    const response = await defaultDataProvider.getOne(resource, params);

    return { data: { ...response.data, id: response.data?.id || params.id } };
  },
  update: async (resource, params) => {
    const response = await defaultDataProvider.update(resource, params);

    return { data: { ...response.data, id: response.data?.id || params.id } };
  },
};
