import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Datagrid, DateField, FunctionField, useDataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { List } from '../../components/molucules/custom-react-admin';
import { numberFormat } from '../../util/function';

const TotalSale = ({ id }: { id: string | undefined }) => {
  const [totalSale, setTotalSale] = useState<number | undefined>(undefined);
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (id) {
      dataProvider
        .getOne('balance-histories/total-sale', { id })
        .then(({ data }) => data)
        .then((data) => {
          setTotalSale(data.amount);
        });
    }
  }, [id, dataProvider]);

  return (
    <Box className={'totalSale'}>
      <Box className={'totalSaleBox'}>
        <Box className={'totalSaleTitle'}>現在のアカウント保有金額</Box>
        <Box className={'totalSaleNumber'}>{numberFormat(totalSale)}</Box>
      </Box>
    </Box>
  );
};

export const BalanceHistoryAccount = () => {
  const { id } = useParams();

  return (
    <Box sx={styles}>
      <List title={'出金履歴'} hasCreate={false} resource={'balance-histories/account/' + id}>
        <>
          <TotalSale id={id} />
          <Box className={'listBalanceHistory'}>
            <Datagrid optimized bulkActionButtons={false}>
              <DateField
                source="createdAt"
                label="日付"
                showTime
                locales={'ja-jp'}
                sortable={false}
                sortBy={'created_at'}
              />
              <FunctionField
                label="出金額"
                source="amount"
                render={(record: any) => {
                  return record.amount > 0 ? record.amount : numberFormat(record.amount * -1);
                }}
                sortable={false}
              />
            </Datagrid>
          </Box>
        </>
      </List>
    </Box>
  );
};

const styles = {
  '& .listBase': {
    border: 0,
    '& .list': {
      border: 0,
      padding: 0,
    },
    '& .listTitle': {
      border: 0,
      background: '#F7F7F7',
      padding: '20px',
    },
  },

  '& .totalSale': {
    width: '100%',
    padding: '25px 0 ',
    margin: '0',
    background: '#F7F7F7',
    '& .totalSaleBox': {
      width: '50%',
      background: '#FFF',
      padding: '25px',
      '& .totalSaleTitle': {
        fontWeight: 600,
      },
      '& .totalSaleNumber': {
        fontSize: 30,
        fontWeight: 600,
      },
    },
  },

  '& .listBalanceHistory': {
    // background: '#FFF',
    padding: '20px',
    '& .column-createdAt': {
      width: { xs: '50%', md: '30%' },
    },
    '& .column-amount': {
      width: { xs: '50%', md: '70%' },
      textAlign: 'left',
    },
  },
  '& .pagination': {
    '& .MuiTablePagination-toolbar': {
      // background: '#FFF',
    },
  },
};
