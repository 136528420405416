import React from 'react';
import { SvgIcon } from '@mui/material';

interface IRadioIcon {
  color?: string;
  border?: string;
}

export default function Radio({ color = '#fff', border = '#999' }: IRadioIcon): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 25 25'}>
      <g id="Ellipse_42" data-name="Ellipse 42" fill={color} stroke={border} strokeWidth="1">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
        <circle cx="12.5" cy="12.5" r="12" fill="none" />
      </g>
    </SvgIcon>
  );
}
