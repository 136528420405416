import React from 'react';
import { SvgIcon } from '@mui/material';

interface IRadioCheckedIcon {
  color1?: string;
  color2?: string;
}

export default function RadioChecked({ color1 = '#000', color2 = '#FFF' }: IRadioCheckedIcon): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 25 25'}>
      <g stroke={color1} strokeWidth="1">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
        <circle cx="12.5" cy="12.5" r="12" fill="none" />
      </g>
      <circle cx="4.5" cy="4.5" r="4.5" transform="translate(8 8)" fill={color2} />
    </SvgIcon>
  );
}
