import { ListItemIcon } from '@mui/material';
import { RentalList } from './list';
import { RentalShow } from './show';

export default {
  menuIcon: ListItemIcon,
  options: { label: 'レンタル管理' },
  list: RentalList,
  show: RentalShow,
};
