import { useCreateContext, useEditContext, useNotify, useRedirect } from 'react-admin';
import { FormGroup, FormGroupButton, useVForm, VFormContainer } from '../../atoms/form';
import { BackButton, FormInput, IFormInput } from './index';
import { CommonButton } from '../../atoms/button/common-button';
import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

type props = {
  defaultValues?: any;
  onSuccess?: (values: any) => void;
  onError?: (values: any) => void;
  data: IFormInput[];
  beforeFormChild?: React.ReactNode;
  afterFormChild?: React.ReactNode;
  transformData?: (data: any) => any;
  renderButtonSubmit?: (record?: any) => React.ReactNode;
  renderButtonBack?: (record?: any) => React.ReactNode;
};

export const Form = ({
  defaultValues,
  onSuccess,
  onError,
  data: formInputData,
  beforeFormChild,
  afterFormChild,
  transformData,
  renderButtonSubmit,
  renderButtonBack,
}: props) => {
  const { id: routeId } = useParams<'id'>();
  const formContext = useVForm({
    defaultValues: defaultValues,
  });
  const editContext = useEditContext();
  const createContext = useCreateContext();
  const context = routeId ? editContext : createContext;
  const { record, isLoading, resource, save, saving } = context;

  const notify = useNotify();
  const redirect = useRedirect();

  const getFormData = (formInputData: IFormInput[], formData: any) => {
    const submitData: Record<string, any> = {};
    if (formData) {
      formInputData.forEach((formInput) => {
        if (formInput.name in formData) {
          const value = formData[formInput.name];

          // check value exist in options
          if (formInput.type === 'select' || formInput.type === 'radio' || formInput.type === 'checkbox') {
            const { options } = formInput;
            if (
              !options?.find((option) => {
                return option.id === value;
              })
            ) {
              // value not exist in options => ''
              submitData[formInput.name] = '';
              return;
            }
          }

          submitData[formInput.name] = value;
        }
      });
    }

    return submitData;
  };

  useEffect(() => {
    const formData = getFormData(formInputData, record);
    formContext.reset(formData);
  }, [record, formContext, formInputData]);

  if (isLoading) return <></>;

  const submitHandler = async (data: any) => {
    if (saving) {
      return;
    }

    save &&
      save(data, {
        onSuccess: (response) => {
          if (onSuccess) {
            onSuccess(response);
          } else {
            // common success
            notify(routeId ? '更新しました' : '作成した');
            redirect('list', resource, response.id, response);
          }
        },
        onError: (error) => {
          if (onError) {
            onError(error);
          } else {
            // common error
            notify('エラー');
          }
        },
        transform: (formData) => {
          // send only data from define Form Input
          const submitData = getFormData(formInputData, formData);

          return transformData ? transformData(submitData) : submitData;
        },
      });
  };

  return (
    <VFormContainer onSuccess={submitHandler} onError={onError} formContext={formContext}>
      {beforeFormChild}
      {formInputData.map((attr) => {
        return (
          <FormGroup key={attr.name}>
            <FormInput {...attr} record={record} />
          </FormGroup>
        );
      })}
      {afterFormChild}
      <FormGroupButton>
        {renderButtonBack ? renderButtonBack(record) : <BackButton resource={resource} />}
        {renderButtonSubmit ? renderButtonSubmit(record) : <CommonButton text={'保存'} type={'submit'} />}
      </FormGroupButton>
    </VFormContainer>
  );
};

// export const Form = () => <></>;
