import { ControllerProps } from 'react-hook-form';
import errorMessage from './message';

export default function transformValidation(
  validation?: ControllerProps['rules'],
  label?: any,
  required?: any,
): ControllerProps['rules'] | undefined {
  if (!validation) {
    return validation;
  }

  const fieldLabel = label || 'This Field';
  if (required) {
    validation.required = errorMessage('required', fieldLabel);
  }

  if (validation.minLength && !isNaN(validation.minLength as number)) {
    const { minLength } = validation;
    validation.minLength = {
      value: Number(minLength),
      message: errorMessage('min', { field: fieldLabel, length: String(minLength) }),
    };
  }

  if (validation.maxLength && !isNaN(validation.maxLength as number)) {
    const { maxLength } = validation;
    validation.maxLength = {
      value: Number(maxLength),
      message: errorMessage('max', { field: fieldLabel, length: String(maxLength) }),
    };
  }

  if (typeof validation.validate === 'object') {
    for (const key in validation.validate) {
      if (typeof validation.validate[key] === 'function') {
        const callback = validation.validate[key];
        validation.validate[key] = function (v) {
          // @ts-ignore
          return callback.apply(this, [v, fieldLabel]);
        };
      }
    }
  }

  return validation;
}
