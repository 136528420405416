import * as React from 'react';
import { ReactNode } from 'react';
import { Datagrid, TextField, ShowButton, WrapperField, FunctionField, DateField } from 'react-admin';
import { List, ImageField, FilterFormInputProps } from '../../components/molucules/custom-react-admin';
import { Box, InputLabel } from '@mui/material';
import { CustomLink } from '../../components/atoms/custom-link';
import { LABEL_WIDTH, LABEL_STYLE } from '../../util/constant/form';
import { VTextField, VDatePicker } from '../../components/atoms/form';
import { ExportCsvForm } from '../../components/molucules/custom-react-admin/export-csv-form';
import { BASE_API_URL } from '../../providers/data-provider';

const GENRE_OPTIONS = [
  { id: '抽象', label: '抽象' },
  { id: '人物', label: '人物' },
  { id: '風景', label: '風景' },
  { id: '動物・生き物', label: '動物・生き物' },
  { id: '花・植物', label: '花・植物' },
  { id: 'その他', label: 'その他' },
  { id: '抽象', label: '抽象' },
];

const PriceFilter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .vPriceLabel': {
          width: '100%',
          maxWidth: LABEL_WIDTH,
        },
        '& .vPriceSpacing': {
          marginLeft: '10px',
        },
        '& .vPriceInput': {
          maxWidth: '40%',
        },
      }}
    >
      <Box className={'vPriceLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>単価</InputLabel>
      </Box>
      <Box className={'vPriceInput'}>
        <VTextField name={'priceFrom'} type={'number'} preventDot preventNegativeNumber />
      </Box>
      <Box className={'vPriceSpacing'}>~</Box>
      <Box className={'vPriceSpacing vPriceInput'}>
        <VTextField name={'priceTo'} type={'number'} preventDot preventNegativeNumber />
      </Box>
    </Box>
  );
};

const CreatedAtFilter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .vPriceLabel': {
          width: '100%',
          maxWidth: LABEL_WIDTH,
        },
        '& .vPriceSpacing': {
          marginLeft: '10px',
        },
        '& .vPriceInput': {
          maxWidth: '40%',
        },
      }}
    >
      <Box className={'vPriceLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>登録日</InputLabel>
      </Box>
      <Box className={'vPriceInput'}>
        <VDatePicker name={'createdAtFrom'} />
      </Box>
      <Box className={'vPriceSpacing'}>~</Box>
      <Box className={'vPriceSpacing vPriceInput'}>
        <VDatePicker name={'createdAtTo'} />
      </Box>
    </Box>
  );
};

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: '作品ID', name: 'id' },
  { type: 'input', label: 'アーティスト名', name: 'artistName' },
  { type: 'input', label: 'タイトル', name: 'title' },
  { type: 'input', label: 'アーティストID', name: 'artistId' },
  { type: 'select', label: 'ジャンル', name: 'genre', options: GENRE_OPTIONS },
  { type: 'render', label: '単価', name: 'price', render: () => <PriceFilter /> },
  { type: 'render', label: '登録日', name: 'createdAt', render: () => <CreatedAtFilter /> },
];

export const ArtworkList = (props: { children?: ReactNode }) => {
  return (
    <List {...props} title={'作品管理一覧'} hasCreate={false} filters={filterForm}>
      <ExportCsvForm apiUrl={BASE_API_URL + '/artworks/export-csv'} />
      <Datagrid bulkActionButtons={false} optimized>
        <TextField source="id" label="作品ID" sortable={true} />
        <FunctionField
          label="アーティスト名"
          render={(record: any) => {
            return <CustomLink resource={'artists'} type={'show'} id={record.artistId} title={record.artistName} />;
          }}
          sortable={true}
          sortBy={'artist_id'}
        />
        <FunctionField
          label="アーティストID "
          render={(record: any) => {
            return <CustomLink resource={'artists'} type={'show'} id={record.artistId} title={record.artistId} />;
          }}
          sortable={true}
          sortBy={'artist_id'}
        />
        <ImageField source="mainImagePath" label="画像" sortable={false} />
        <TextField source="title" label="タイトル" sortable={true} />
        <FunctionField
          label="ジャンル"
          render={(record: any) => {
            return record.genreList?.join(', ');
          }}
          sortable={false}
        />
        <DateField source="createdAt" label={'登録日'} sortable={true} sortBy={'created_at'} locales={'ja-jp'} />
        <WrapperField label={'操作'}>
          <ShowButton className={'buttonNoIcon'} label={'詳細'} />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
