import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useListContext } from 'react-admin';
import { useVForm, VFormContainer } from '../../atoms/form';
import { FilterFormInput, FilterFormInputProps } from './filter-form-input';
import { CommonButton } from '../../atoms/button/common-button';
import { Box, Card, Grid } from '@mui/material';

type props = {
  filters: FilterFormInputProps[];
  defaultValues?: object;
};

export const FilterForm = ({ filters, defaultValues }: props) => {
  const { displayedFilters, filterValues, setFilters } = useListContext();

  // const filters = useContext(FilterContext) || filtersProps;
  //
  const mergedValues = mergeInitialValuesWithDefaultValues(defaultValues || filterValues, filters);

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      setFilters(values, displayedFilters);
    }
  };

  // const resetFilter = () => {
  //   setFilters({}, []);
  // };

  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const formContext = useVForm({
    defaultValues: {},
  });

  useEffect(() => {
    formContext.reset({ ...mergedValues });
    // eslint-disable-next-line
  }, []);

  const handleResetForm = () => {
    formContext.reset({});
    setFilters({}, displayedFilters);
  };

  const totalInput = useMemo(() => {
    return filters.filter((filter) => filter.type !== 'hidden').length;
  }, [filters]);

  if (!totalInput) {
    return <></>;
  }

  return (
    <Card sx={styles}>
      <Box className={'filterFormTitle'}>検索</Box>
      <Box className={'filterFormBody'}>
        <VFormContainer formContext={formContext} onSuccess={onSubmit}>
          <Grid container>
            {filters.map((props, index) => {
              return (
                <Grid item xs={12} md={props.size || 6} key={index} className={'filterFormRow'}>
                  <FilterFormInput {...props} />
                </Grid>
              );
            })}
          </Grid>
          <Box className={'filterFormButton'}>
            <CommonButton text={'検索'} type={'submit'} />
            <CommonButton
              text={'リセット'}
              type={'button'}
              variant={'white'}
              className={'filterFormButtonReset'}
              onClick={handleResetForm}
            />
          </Box>
        </VFormContainer>
      </Box>
    </Card>
  );
};

const mergeInitialValuesWithDefaultValues = (initialValues: any[], filters: FilterFormInputProps[]) => {
  const values = filters
    .filter((filterElement) => filterElement.show && filterElement.defaultValue)
    .reduce((acc: Record<any, any>, filterElement) => {
      return {
        ...acc,
        [filterElement.name]: filterElement.defaultValue,
      };
    }, {} as Record<any, any>);

  return {
    ...values,
    ...initialValues,
  };
};

const styles = {
  margin: '16px 0',
  borderRadius: '5px',

  '& .filterFormTitle': {
    padding: '16px',
    background: '#F5F5F5',
  },

  '& .filterFormBody': {
    '& .filterFormRow': {
      padding: '16px',
    },
  },

  '& .filterFormButton': {
    padding: '16px 0',
    paddingLeft: {
      xs: '0',
      sm: '216px',
    },

    '& .filterFormButtonReset': {
      marginLeft: '10px',
    },
  },
};
