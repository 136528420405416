import type { PageRootResourceProps } from '../../types/react-admin';
import NoteList from './list';
import NewsShow from './show';
import NoteEdit from './edit';
import NoteCreate from './create';

const resourceConfigs: PageRootResourceProps = {
  options: { label: 'コンテンツ' },
  list: NoteList,
  show: NewsShow,
  edit: NoteEdit,
  create: NoteCreate,
};

export default resourceConfigs;
