import * as React from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { numberFormat } from '../../util/function';

const RenderCareer = ({
  record,
  attrName,
  attrYearName,
  total,
}: {
  record: any;
  attrName: string;
  attrYearName: string;
  total: number;
}) => {
  return (
    <>
      {[...Array(total)].map((_, index) => {
        const year = record[`${attrYearName}${index + 1}`] || '';
        const text = record[`${attrName}${index + 1}`] || '';
        return (
          <React.Fragment key={index}>
            {(year || text) && (
              <div>
                {year}
                <span style={{ paddingLeft: '20px' }} />
                {text}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const RenderPastSale = ({ record, total }: { record: any; total: number }) => {
  return (
    <>
      {[...Array(total)].map((_, index) => {
        const pastSalesLocation = record['pastSalesLocation' + (index + 1)] || '';
        const pastAverageSalesPrice = record['pastAverageSalesPrice' + (index + 1)] || '';
        const pastSalesCount = record['pastSalesCount' + (index + 1)] || '';
        return (
          <React.Fragment key={index}>
            <div>販売場所: {pastSalesLocation}</div>
            <div>平均価格: {numberFormat(pastAverageSalesPrice)}</div>
            <div>販売点数: {numberFormat(pastSalesCount)}</div>
            {index < total - 1 && <br />}
          </React.Fragment>
        );
      })}
    </>
  );
};

const RenderAssumption = ({ record }: { record: any }) => {
  const assumptionAveragePrice = record['assumptionAveragePrice'] || '';
  const assumptionSalesCount = record['assumptionSalesCount'] || '';
  return (
    <>
      <div>平均価格: {numberFormat(assumptionAveragePrice)}</div>
      <div>想定出品点数: {numberFormat(assumptionSalesCount)}</div>
    </>
  );
};

const attrs: IFormInputValue[] = [
  {
    label: '申請ID',
    name: 'id',
  },
  {
    label: 'アーティスト名',
    name: 'artistName',
  },
  {
    label: '主な受賞歴・入賞歴',
    name: 'award',
    render: (record) => {
      return <RenderCareer record={record} attrName={'award'} attrYearName={'awardYear'} total={3} />;
    },
  },
  {
    label: '主な個展',
    name: 'soloExhibition',
    render: (record) => {
      return <RenderCareer record={record} attrName={'soloExhibition'} attrYearName={'soloExhibitionYear'} total={3} />;
    },
  },
  {
    label: '主なグループ展',
    name: 'groupExhibition',
    render: (record) => {
      return (
        <RenderCareer record={record} attrName={'groupExhibition'} attrYearName={'groupExhibitionYear'} total={3} />
      );
    },
  },
  {
    label: '過去の販売実績',
    name: 'pastSalesLocation',
    render: (record) => {
      return <RenderPastSale record={record} total={2} />;
    },
  },
  {
    label: '出品予定作品',
    name: 'examinationImage1',
    type: 'image',
  },
  {
    label: ' ',
    name: 'examinationImage2',
    type: 'image',
  },
  {
    label: ' ',
    name: 'examinationImage3',
    type: 'image',
  },
  {
    label: ' ',
    name: 'examinationImage4',
    type: 'image',
  },
  {
    label: ' ',
    name: 'examinationImage5',
    type: 'image',
  },
  {
    label: '想定販売価格',
    name: 'assumption',
    render: (record) => {
      return <RenderAssumption record={record} />;
    },
  },
  {
    label: 'ウェブサイト',
    name: 'webSite',
  },
  {
    label: 'ツイッター',
    name: 'twitter',
    render: (record) => {
      return record['twitter'] ? 'https://www.twitter.com/' + record['twitter'] : '';
    },
  },
  {
    label: 'インスタグラム',
    name: 'instagram',
    render: (record) => {
      return record['instagram'] ? 'https://www.instagram.com/' + record['instagram'] : '';
    },
  },
  {
    label: 'フェイスブック',
    name: 'facebook',
    render: (record) => {
      return record['facebook'] ? 'https://www.facebook.com/' + record['facebook'] : '';
    },
  },
  {
    label: '意気込み',
    name: 'enthusiasm',
  },
  {
    label: '申請日',
    name: 'createdAt',
    type: 'datetime',
  },
];

export const ArtistApplicantShowInfo = () => {
  return <RecordInfo data={attrs} />;
};

export const ArtistApplicantShow = () => {
  return (
    <Show recordTitleAttribute={'artistName'} hasEdit={true}>
      <ArtistApplicantShowInfo />
    </Show>
  );
};
