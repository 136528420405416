import React, { createElement, useState } from 'react';
import {
  FormControlLabelProps,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Control, Controller, ControllerProps, FieldError } from 'react-hook-form';
import transformValidation from '../common/validation';
import RequiredLabel from '../required-label';
import { FORM_GRID, INPUT_STYLE, LABEL_STYLE } from '../../../../util/constant/form';
import { className } from '../../../../util/function';

export type VSelectProps = Omit<TextFieldProps, 'name' | 'type' | 'onChange'> & {
  validation?: ControllerProps['rules'];
  name: string;
  options?: any[];
  valueKey?: string;
  labelKey?: string;
  type?: 'string' | 'number';
  parseError?: (error: FieldError) => string;
  objectOnChange?: boolean;
  onChange?: (value: any) => void;
  control?: Control<any>;
  onError?: (error: FieldError | undefined) => void;
  errorLabel?: FormControlLabelProps['label'];
  showRequiredText?: boolean;
};

export default function VSelect({
  name,
  required,
  valueKey = 'id',
  labelKey = 'label',
  options = [],
  parseError,
  type,
  objectOnChange,
  validation = {},
  control,
  onError,
  errorLabel,
  showRequiredText = true,
  label,
  ...rest
}: VSelectProps): JSX.Element {
  const isNativeSelect = !!rest.SelectProps?.native;
  const ChildComponent = isNativeSelect ? 'option' : MenuItem;
  // @ts-ignore
  validation = transformValidation(validation, errorLabel || label, required);
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { invalid, error } }) => {
        // handle shrink on number input fields
        if (type === 'number' && value) {
          rest.InputLabelProps = rest.InputLabelProps || {};
          rest.InputLabelProps.shrink = true;
        }
        if (typeof value === 'object') {
          value = value[valueKey]; // if value is object get.md.md key
        }

        error && onError && onError(error);
        return (
          <Grid container className={className({ hasLabel: !!label })} sx={FORM_GRID}>
            {label && (
              <Grid item className={'formGridLabel'}>
                <InputLabel focused={isFocus} error={!!error} sx={LABEL_STYLE}>
                  {label} {required && showRequiredText ? <RequiredLabel /> : ''}
                </InputLabel>
              </Grid>
            )}
            <Grid item className={'formGridInput'}>
              <TextField
                {...rest}
                label={''}
                name={name}
                value={value || ''}
                onBlur={(e) => {
                  setIsFocus(false);
                  rest.onBlur && rest.onBlur(e);
                  return onBlur;
                }}
                onFocus={(e) => {
                  setIsFocus(true);
                  rest.onFocus && rest.onFocus(e);
                }}
                onChange={(event) => {
                  let item: number | string = event.target.value;
                  if (type === 'number') {
                    item = Number(item);
                  }
                  onChange(item);
                  if (typeof rest.onChange === 'function') {
                    if (objectOnChange) {
                      item = options.find((i) => i[valueKey] === item);
                    }
                    rest.onChange(item);
                  }
                }}
                select
                required={required}
                error={invalid}
                sx={INPUT_STYLE}
                variant={'outlined'}
                fullWidth
                className={'VSelect'}
                SelectProps={{ MenuProps: { sx: { '& li.MuiMenuItem-root': { whiteSpace: 'pre-line' } } } }}
              >
                {isNativeSelect && <option />}
                {options.map((item: any) =>
                  createElement(
                    ChildComponent,
                    {
                      key: `${name}_${item[valueKey]}`,
                      value: item[valueKey],
                    },
                    item[labelKey],
                  ),
                )}
              </TextField>
              {error && (
                <FormHelperText error={true}>
                  {typeof parseError === 'function' ? parseError(error) : error.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
