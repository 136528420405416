import * as React from 'react';
import { ReactNode } from 'react';
import { FilterFormInputProps, List } from '../../components/molucules/custom-react-admin';
import { Datagrid, DateField, ShowButton, TextField, WrapperField } from 'react-admin';
import { VTextField } from '../../components/atoms/form';
import { Box, InputLabel } from '@mui/material';
import { LABEL_STYLE, LABEL_WIDTH } from '../../util/constant/form';
import { ExportForm } from './export';

const EntriesFilter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .vEntryLabel': {
          width: '100%',
          maxWidth: LABEL_WIDTH,
        },
        '& .vEntrySpacing': {
          marginLeft: '10px',
        },
        '& .vEntryInput': {
          maxWidth: '40%',
        },
      }}
    >
      <Box className={'vEntryLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>出品数</InputLabel>
      </Box>
      <Box className={'vEntryInput'}>
        <VTextField name={'count_artwork_from'} type={'number'} preventDot preventNegativeNumber />
      </Box>
      <Box className={'vEntrySpacing'}>~</Box>
      <Box className={'vEntrySpacing vEntryInput'}>
        <VTextField name={'count_artwork_to'} type={'number'} preventDot preventNegativeNumber />
      </Box>
    </Box>
  );
};

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: 'アーティストID', name: 'id' },
  { type: 'input', label: 'アーティスト名', name: 'artist_name' },
  { type: 'render', label: '出品数', name: 'entries', render: EntriesFilter },
];

export const ArtistList = (props: { children?: ReactNode }) => {
  return (
    <List {...props} title={'アーティスト'} hasCreate={false} filters={filterForm}>
      <ExportForm />
      <Datagrid bulkActionButtons={false} optimized>
        <TextField source="id" label="アーティストID" sortable={true} />
        <DateField source="createdAt" label="登録日" sortable={true} showTime locales={'ja-jp'} sortBy={'created_at'} />
        <TextField source="artistName" label="アーティスト名" sortable={true} sortBy={'artist_name'} />
        <TextField source="countArtwork" label="出品数" sortable={true} />
        <WrapperField label={'操作'}>
          <ShowButton className={'buttonNoIcon'} label={'詳細'} />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
