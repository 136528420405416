import React from 'react';
import { Button } from '@mui/material';

interface props {
  text: string;
  type?: 'submit' | 'button';
  variant?: 'blue' | 'green' | 'white' | 'black' | 'gray' | 'none';
  fullWidth?: boolean;
  onClick?: (e: any) => void;
  className?: string;
  disabled?: boolean;
}

export const CommonButton = ({
  text,
  type = 'submit',
  variant = 'blue',
  onClick,
  fullWidth,
  className,
  disabled = false,
}: props): JSX.Element => {
  // default variant black
  let backgroundColor = '#3F7AB7';
  let color = '#FFF';
  let borderColor = '#3F7AB7';

  if (variant === 'green') {
    backgroundColor = '#5CB85C';
    color = '#FFF';
    borderColor = '#5CB85C';
  }

  if (variant === 'white') {
    backgroundColor = '#FFF';
    color = '#000';
    borderColor = '#000';
  }

  if (variant === 'black') {
    backgroundColor = '#000';
    color = '#FFFF';
    borderColor = '#FFF';
  }

  if (variant === 'gray') {
    backgroundColor = '#ccc';
    color = '#000';
    borderColor = '#ccc';
  }

  if (variant === 'none') {
    backgroundColor = 'rgba(0,0,0,0)';
    color = '#73879D';
    borderColor = 'rgba(0,0,0,0)';
  }

  const styles = {
    backgroundColor: `${backgroundColor} !important`,
    color: `${color} !important`,
    borderColor: `${borderColor} !important`,
    width: fullWidth ? '100%' : 'auto',
    borderRadius: '6px',
    boxShadow: 'none',
    border: '1px solid',
    padding: '6px 10px',
    lineHeight: '1rem',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: `${backgroundColor} !important`,
      color: `${color} !important`,
      borderColor: `${borderColor} !important`,
      border: '1px solid',
      boxShadow: 'none',
      opacity: 0.9,
    },
    '&.Mui-disabled': {
      opacity: 0.6,
    },
  };

  return (
    <Button
      variant="contained"
      type={type}
      onClick={(event: any) => {
        onClick && onClick(event);
      }}
      sx={styles}
      className={className}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
