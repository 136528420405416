import * as React from 'react';
import { ReactNode, useState } from 'react';
import { FilterFormInputProps, List } from '../../components/molucules/custom-react-admin';
import {
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ShowButton,
  TextField,
  useListContext,
  useNotify,
  useRefresh,
  WrapperField,
} from 'react-admin';
import { Box, InputLabel } from '@mui/material';
import { LABEL_STYLE, LABEL_WIDTH } from '../../util/constant/form';
import { VTextField } from '../../components/atoms/form';
import { CustomLink } from '../../components/atoms/custom-link';
import { CommonButton } from '../../components/atoms/button/common-button';
import { axiosBackendClient } from '../../util/axios';
import { ModalCancel } from '../../components/molucules/modal/modal-cancel';
import { FRONT_BASE_URL } from '../../util/constant/api-url';

export const SALE_STATE = {
  authorized: 'オーソリ取得済み',
  salesRecorded: '売上計上済み',
} as const;

const PriceFilter = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .vPriceLabel': {
          width: '100%',
          maxWidth: LABEL_WIDTH,
        },
        '& .vPriceSpacing': {
          marginLeft: '10px',
        },
        '& .vPriceInput': {
          maxWidth: '40%',
        },
      }}
    >
      <Box className={'vPriceLabel'}>
        <InputLabel sx={{ ...LABEL_STYLE, paddingTop: 0 }}>単価</InputLabel>
      </Box>
      <Box className={'vPriceInput'}>
        <VTextField name={'priceFrom'} type={'number'} preventDot preventNegativeNumber />
      </Box>
      <Box className={'vPriceSpacing'}>~</Box>
      <Box className={'vPriceSpacing vPriceInput'}>
        <VTextField name={'priceTo'} type={'number'} preventDot preventNegativeNumber />
      </Box>
    </Box>
  );
};

const CompleteButton = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { selectedIds } = useListContext();
  const [isCallApi, setIsCallApi] = useState(false);

  const handleCompletePurchase = async () => {
    if (isCallApi) {
      return;
    }
    if (selectedIds?.length) {
      try {
        await axiosBackendClient.post<ResponseType>('/purchase-histories/update-completed', { ids: selectedIds });
        refresh();
        notify('取引完了');
      } catch (e) {
        // @ts-ignore
        notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
      }
    }
    setIsCallApi(false);
  };

  return (
    <CommonButton
      text={'取引完了にする'}
      className={'listButtonCommon buttonNoIcon'}
      onClick={handleCompletePurchase}
    />
  );
};

const filterForm: FilterFormInputProps[] = [
  { type: 'input', label: '注文ID', name: 'id' },
  { type: 'input', label: '購入者名', name: 'purchaserNickname' },
  { type: 'render', label: '単価', name: 'price', render: () => <PriceFilter /> },
  { type: 'input', label: '作品ID', name: 'artworkId' },
  { type: 'datetime', label: '購入日', name: 'purchaseDate' },
];

export const PurchaseHistoryList = (props: { children?: ReactNode }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [isCallApi, setIsCallApi] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState<string>('');
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelPurchaseClick = (id: any) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCancelPurchase = async () => {
    if (isCallApi) {
      return;
    }
    setIsCallApi(true);

    try {
      await axiosBackendClient.post<ResponseType>(
        '/purchase-histories/cancel/' + id,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      refresh();

      notify('キャンセル完了');
    } catch (e) {
      // @ts-ignore
      notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
    }

    setIsCallApi(false);
    setOpenModal(false);
  };

  return (
    <List {...props} title={'購入管理'} hasCreate={false} filters={filterForm}>
      <Box sx={styles}>
        <Box className={'listTitle'}>
          <Box className={'listTitleButton'}>
            <CompleteButton />
          </Box>
          <Box sx={{ overflow: 'auto' }} />
        </Box>
        <Datagrid bulkActionButtons={<></>} optimized>
          <TextField source="id" label="注文ID" sortable={true} />
          <FunctionField
            label="アカウント名"
            render={(record: any) => {
              return (
                <CustomLink
                  resource={'accounts'}
                  type={'show'}
                  id={record.purchaserId}
                  title={record.purchaserNickname}
                />
              );
            }}
            sortable={true}
            sortBy={'purchaserNickname'}
          />
          <FunctionField
            label="送付先氏名"
            render={(record: any) => {
              return (
                <a
                  href={`${FRONT_BASE_URL}member/${record.purchaserId}`}
                  target={'_blank'}
                  style={{ textDecoration: 'none' }}
                >
                  {record.deliveryNickname}
                </a>
              );
            }}
            sortable={true}
            sortBy={'artworkTitle'}
          />
          <FunctionField
            label="作品名"
            render={(record: any) => {
              return (
                <CustomLink resource={'artworks'} type={'show'} id={record.artworkId} title={record.artworkTitle} />
              );
            }}
            sortable={true}
            sortBy={'artworkTitle'}
          />
          <NumberField source="price" label="単価" sortable={true} />
          <FunctionField
            label="ステータス"
            sortable={true}
            sortBy={'sale_state'}
            render={(record: any) => (record.saleState === SALE_STATE.salesRecorded ? '取引完了' : record.saleState)}
          />
          <DateField
            source="purchaseDate"
            label="購入日"
            sortable={true}
            showTime
            locales={'ja-jp'}
            sortBy={'purchase_date'}
          />
          <DateField
            source="deliveryCompletedAt"
            label="配送完了日"
            sortable={true}
            showTime
            locales={'ja-jp'}
            sortBy={'deliveryCompletedAt'}
          />
          <FunctionField
            label="キャンセル状態"
            name="cancel"
            source="cancel"
            render={(record: any) => (record.canceledAt || record.adminCancelAcceptAt ? 'キャンセル' : '')}
          />
          <FunctionField
            label="配送ステータス"
            name="deliveryStatus"
            source="deliveryStatus"
            sortable={false}
            render={(record: any) => (record.deliveryStatus ? record.deliveryStatus : '未発送')}
          />
          <WrapperField label={'操作'}>
            <FunctionField
              label="Name"
              render={(record: any) => (
                <CommonButton
                  text={'キャンセル'}
                  type={'button'}
                  className={'buttonNoIcon'}
                  variant={'none'}
                  onClick={() => handleCancelPurchaseClick(record.id)}
                />
              )}
            />
            <ShowButton className={'buttonNoIcon'} label={'表示'} />
          </WrapperField>
        </Datagrid>
        <ModalCancel id={id} open={openModal} onClose={handleCloseModal} onSubmit={handleCancelPurchase} />
      </Box>
    </List>
  );
};

const styles = {
  '.column-price,.column-cancel,.column-deliveryStatus': {
    whiteSpace: 'nowrap',
  },
};
