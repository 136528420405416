import { NoteCategory, NoteOpenStatus } from '../../interface/note';

export const BUTTON_LABEL = {
  CREATE: '作成',
  EDIT: '編集',
  DELETE: '削除',
  SAVE: '保存',
  CANCEL: 'キャンセル',
};

/**
 * Noteのカテゴリ識別子の表示内容
 */
export const NOTE_CATEGORY_FIELD_LABEL = {
  [NoteCategory.news]: 'お知らせ',
  [NoteCategory.feature]: 'コンテンツ',
} as const;

/**
 * Noteの公開ステータス識別子の表示内容
 */
export const NOTE_OPEN_STATUS_FIELD_LABEL = {
  [NoteOpenStatus.open]: '公開',
  [NoteOpenStatus.close]: '非公開',
} as const;
