import * as React from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { Box } from '@mui/material';
import { FRONT_BASE_URL } from '../../util/constant/api-url';
import { CustomLink } from '../../components/atoms/custom-link';

const attrs: IFormInputValue[] = [
  { label: '注文ID', name: 'id' },
  { label: '状態', name: 'action' },
  { label: '注文日', name: 'createdAt', type: 'datetime' },
  { label: '到着日', name: 'delivery.completeAt', type: 'datetime' },
  {
    label: '貸出日数',
    name: 'rentalDay',
    render: (record) => {
      return record.rentalDay ? record.rentalDay + '日' : '';
    },
  },
  { label: 'stripeサブスクリプションID', name: 'stripeSubscriptionId' },
  { label: '注文単位で配当される収益金金額', name: 'rentalProfit' },
  { label: '注文者ID', name: 'renterId' },
  {
    label: '注文者（サイト名）',
    name: 'renterNickname',
    render: (record) => {
      const url = record.renterExhibitorId
        ? `${FRONT_BASE_URL}exhibitor/${record.renterId}`
        : `${FRONT_BASE_URL}member/${record.renterId}`;
      return (
        <a href={url} target={'_blank'}>
          {record.renterNickname}
        </a>
      );
    },
  },
  { label: '注文者（送付先名）', name: 'deliveryNickname' },
  {
    label: '送付先住所',
    name: 'deliveryToAddress',
    render: (record) => {
      return (
        <>
          {record.delivery && (
            <React.Fragment>
              {(record.delivery.toPostCode || '') + ' ' + (record.delivery.toPrefecture || '')}
              <br />
              {(record.delivery.toCity || '') + (record.delivery.toTown || '') + (record.delivery.toBuildingInfo || '')}
              <br />
            </React.Fragment>
          )}
        </>
      );
    },
  },
  {
    label: '電話番号（送付先）',
    name: 'deliveryToPhone',
    render: (record: any) => {
      return record.delivery
        ? record.delivery.toTel1 + ' - ' + record.delivery.toTel2 + ' - ' + record.delivery.toTel3
        : '';
    },
  },
  {
    label: 'キャンセル状態',
    name: 'キャンセル状態',
    render: (record: any) => {
      return record.subscriptionCanceled ? 'キャンセル' : '';
    },
  },
  { label: '作品ID', name: 'artworkId' },
  {
    label: '作品名',
    name: 'artworkTitle',
    render: (record) => {
      return <CustomLink resource={'artworks'} type={'show'} id={record.artworkId} title={record.artworkTitle} />;
    },
  },
  { label: 'プラン', name: 'rentalPlan' },
  { label: '送料', name: 'deliveryCharge' },
  { label: '作品状態', name: 'degradedState' },
  { label: 'アーティスト名', name: 'artistName' },
  { label: '販売者ID', name: 'exhibitorId' },
  {
    label: '販売者名',
    name: 'exhibitorNickname',
    render: (record) => {
      const url =
        record.artistAccountId === record.ownerId
          ? `${FRONT_BASE_URL}artist/${record.artistId}`
          : `${FRONT_BASE_URL}exhibitor/${record.exhibitorAccountId}`;
      return (
        <a href={url} target={'_blank'}>
          {record.exhibitorNickname}
        </a>
      );
    },
  },
  {
    label: '発送元住所',
    name: 'deliveryFromAddress',
    render: (record) => {
      return (
        <>
          {record.delivery?.tradeId && (
            <React.Fragment>
              {(record.delivery.fromPostCode || '') + ' ' + (record.delivery.fromPrefecture || '')}
              <br />
              {(record.delivery.fromCity || '') +
                (record.delivery.fromTown || '') +
                (record.delivery.fromBuildingInfo || '')}
              <br />
            </React.Fragment>
          )}
        </>
      );
    },
  },
  {
    label: '電話番号(受取)',
    name: 'deliveryFromPhone',
    render: (record: any) => {
      return record.delivery?.tradeId
        ? record.delivery.fromTel1 + ' - ' + record.delivery.fromTel2 + ' - ' + record.delivery.fromTel3
        : '';
    },
  },
  { label: 'ヤマト運輸取引ID', name: 'delivery.tradeId' },
  { label: '発送日', name: 'delivery.shipAt', type: 'datetime' },
  { label: '配達希望日', name: 'delivery.fromDeliveryDate' },
  { label: '配達時間', name: 'delivery.fromDeliveryTime' },
  {
    label: '集荷タイプ',
    name: 'delivery.pickUp',
    render: (record: any) => {
      return record.delivery?.tradeId ? record.delivery?.pickUp ?? '' : '';
    },
  },
  { label: '配送評価', name: 'deliveryEvaluationAverageRating' },
];

export const RentalShow = () => {
  return (
    <Show recordTitleAttribute={'id'} hasEdit={false}>
      <Box sx={styles}>
        <RecordInfo data={attrs} />
      </Box>
    </Show>
  );
};

const styles = {
  '& .newBox': {
    margin: '20px 30px 0 0',
    '& .formGroupLabel ': {
      '& label': {
        color: '#000 !important',
      },
    },
  },
  '& .formGroupLabel': {
    maxWidth: { xs: '100% !important', sm: '260px !important' },
  },
  '& .formGroupInput': {
    width: { xs: '100% !important', sm: `calc(100% - 260px) !important` },
  },
};
