import * as React from 'react';
import { Edit, Form, IFormInput } from '../../components/molucules/custom-react-admin';
import { ArtistApplicantShowInfo } from './show';
import { VSelect, VTextArea } from '../../components/atoms/form';
import { APPLICANT_STATUS } from '../../util/constant/artist';
import { CommonButton } from '../../components/atoms/button/common-button';

const STATUS_OPTIONS = [
  { id: '完了', label: '完了' },
  { id: '差戻し', label: '差戻し' },
];
const StatusSelect = ({ record }: { record?: any }) => {
  const isCompleted = record?.applicantStatus === APPLICANT_STATUS.complete;

  if (!record) return <></>;

  return (
    <VSelect
      name={'applicantStatus'}
      label={'ステータス'}
      required={!isCompleted}
      options={STATUS_OPTIONS}
      disabled={isCompleted}
    />
  );
};

const CommentTextarea = ({ record }: { record?: any }) => {
  const isCompleted = record?.applicantStatus === APPLICANT_STATUS.complete;

  if (!record) return <></>;

  return (
    <VTextArea name={'comment'} label={'コメント'} required={false} minRows={5} maxRows={5} disabled={isCompleted} />
  );
};

const attrs: IFormInput[] = [
  {
    name: 'applicantStatus',
    label: 'ステータス',
    type: 'select',
    options: STATUS_OPTIONS,
    render: (record) => {
      return <StatusSelect record={record} />;
    },
  },
  {
    name: 'comment',
    label: 'コメント',
    type: 'textarea',
    required: false,
    render: (record) => {
      return <CommentTextarea record={record} />;
    },
  },
];

export const ArtistApplicantEdit = () => {
  return (
    <Edit recordTitleAttribute={'artistName'}>
      <ArtistApplicantShowInfo />
      <Form
        data={attrs}
        renderButtonSubmit={(record) => {
          if (!record) return <></>;

          const isCompleted = record?.applicantStatus === APPLICANT_STATUS.complete;

          return <CommonButton text={'保存'} type={'submit'} disabled={isCompleted} />;
        }}
      />
    </Edit>
  );
};
