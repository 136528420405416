import * as React from 'react';
import { useEffect, useState } from 'react';
import { Show, IFormInputValue, RecordInfo } from '../../components/molucules/custom-react-admin';
import { Image } from '../../components/atoms/image';
import { getFileUrl } from '../..//util/function';
import { Box } from '@mui/material';
import { FormGroup, VSelect, VFormContainer, useVForm } from '../../components/atoms/form';
import { CommonButton } from '../../components/atoms/button/common-button';
import { useNotify } from 'react-admin';
import { axiosBackendClient } from '../../util/axios';
import { FRONT_BASE_URL } from '../../util/constant/api-url';
import { CustomLink } from '../../components/atoms/custom-link';

const IS_PUBLIC_OPTIONS = [
  { label: '公開', id: '1' },
  { label: '非公開', id: '2' },
];

const UpdatePublicStatus = ({ record }: { record: any }) => {
  const formContext = useVForm();
  const notify = useNotify();
  const [isCallApi, setIsCallApi] = useState(false);

  useEffect(() => {
    if (record) {
      formContext.reset({
        isPublic: record.isPublic ? '1' : '2',
      });
    }
  }, [record, formContext]);

  const submitHandler = async (formData: any) => {
    if (isCallApi) {
      return;
    }
    setIsCallApi(true);

    try {
      await axiosBackendClient.post<ResponseType>('/artworks/update-is-public', {
        id: record.id,
        isPublic: formData.isPublic === '1' ? true : false,
      });

      notify('作品を更新しました。');
    } catch (e) {
      // @ts-ignore
      notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
    }

    setIsCallApi(false);
  };

  return (
    <>
      <VFormContainer onSuccess={submitHandler} formContext={formContext}>
        <Box
          sx={{
            '& .formGroupInput': {
              flexDirection: 'row',
            },
            '& .saveIsPublic': {
              alignSelf: 'center',
              marginLeft: '10px',
            },
          }}
        >
          <FormGroup label={'公開状態'}>
            <VSelect name={'isPublic'} options={IS_PUBLIC_OPTIONS} />
            <CommonButton text={'更新'} type={'submit'} className={'saveIsPublic'} />
          </FormGroup>
        </Box>
      </VFormContainer>
    </>
  );
};

const attrs: IFormInputValue[] = [
  {
    label: '作品ID',
    name: 'id',
  },
  {
    label: 'アーティストID',
    name: 'artistId',
    render: (record) => {
      return (
        <a href={`${FRONT_BASE_URL}artist/${record.artistId}`} target={'_blank'}>
          {record.artistId}
        </a>
      );
    },
  },
  {
    label: '所有者ID',
    name: 'ownerId',
    render: (record) => {
      const isArtistOwner = record.artistAccountId === record.ownerId;
      const url = isArtistOwner
        ? `${FRONT_BASE_URL}artist/${record.artistId}`
        : record.ownerExhibitorId
        ? `${FRONT_BASE_URL}exhibitor/${record.ownerId}`
        : `${FRONT_BASE_URL}member/${record.ownerId}`;

      return (
        <a href={url} target={'_blank'}>
          {record.ownerId}
        </a>
      );
    },
  },
  {
    label: 'アーティスト名',
    name: 'artistName',
  },
  {
    label: '所有者名',
    name: 'ownerName',
    render: (record) => {
      const isArtistOwner = record.artistAccountId === record.ownerId;

      return isArtistOwner ? record.artistName : record.ownerName;
    },
  },
  {
    label: '',
    name: 'isPublic',
    render: (record) => {
      return <UpdatePublicStatus record={record} />;
    },
  },
  { label: '出品日付', name: 'createdAt', type: 'datetime' },
  { label: 'タイトル', name: 'title' },
  { label: '作品ストーリー', name: 'createStory' },
  { label: '技法', name: 'media' },
  {
    label: '額縁',
    name: 'withFrame',
    render: (record) => {
      return record['withFrame'] ? '有' : '無';
    },
  },
  {
    label: 'カラー',
    name: 'color1',
    render: (record) => {
      return record.colorList?.[0] ?? '';
    },
  },
  {
    label: 'カラー',
    name: 'color2',
    render: (record) => {
      return record.colorList?.[1] ?? '';
    },
  },
  {
    label: 'カラー',
    name: 'color3',
    render: (record) => {
      return record.colorList?.[2] ?? '';
    },
  },
  { label: 'ジャンル', name: 'genreList', type: 'array' },
  // { label: '壁掛け', name: 'isWallHanging', type: 'boolean' },
  { label: 'レンタル', name: 'rental' },
  { label: '単価', name: 'purchase', type: 'number' },
  {
    label: '配送目安',
    name: 'estimatedShippingDate',
    render: (record) => {
      if (record.estimatedShippingDate === 7) {
        return '1週間';
      }
      if (record.estimatedShippingDate === 14) {
        return '2週間';
      }
      return record.estimatedShippingDate;
    },
  },
  { label: 'メイン画像', name: 'mainImagePath', type: 'image' },
  {
    label: 'サブ画像',
    name: 'artworkDetailImageList',
    render: (record) => {
      return (
        <>
          {record.artworkDetailImageList?.map((image: any, index: number) => (
            <Box sx={{ marginTop: '10px', display: 'inline-block' }} key={index}>
              <Image src={getFileUrl(image.imagePath)} maxWidth={200} />
            </Box>
          ))}
        </>
      );
    },
  },
  { label: '制作年', name: 'createYear', type: 'year' },
  {
    label: 'サイズ',
    name: 'size',
    render: (record) => {
      return (
        <Box>
          <span className={'mr-30'}>
            縦 <span className={'pl-15'}>{record.height}</span> cm
          </span>
          <br />
          <span>
            横 <span className={'pl-15'}>{record.width}</span> cm
          </span>
        </Box>
      );
    },
  },
  {
    label: '作品状態',
    name: 'degradedState',
  },
  {
    label: '流通種別',
    name: 'edition',
  },
  {
    label: '取引履歴',
    name: 'purchaseHistory',
    render: (record) => {
      return (
        <Box>
          {record.purchaseHistory?.map((purchaseId: string) => (
            <CustomLink resource={'purchase-histories'} type={'show'} id={purchaseId} title={purchaseId} />
          ))}
        </Box>
      );
    },
  },
];

export const ArtworkShow = () => {
  return (
    <Show recordTitleAttribute={'id'} hasEdit={false}>
      <RecordInfo data={attrs} />
    </Show>
  );
};
