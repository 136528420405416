import { ListItemIcon } from '@mui/material';
import { ArtistApplicantList } from './list';
import { ArtistApplicantEdit } from './edit';
import { ArtistApplicantShow } from './show';

export default {
  list: ArtistApplicantList,
  menuIcon: ListItemIcon,
  edit: ArtistApplicantEdit,
  show: ArtistApplicantShow,
  options: { label: 'アーティスト申請管理' },
};
