import { ListItemIcon } from '@mui/material';
import { AccountList } from './list';
import { AccountShow } from './show';

export default {
  list: AccountList,
  menuIcon: ListItemIcon,
  show: AccountShow,
  options: { label: 'アカウント管理' },
};
