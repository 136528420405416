import * as React from 'react';
import { ReactNode } from 'react';
import { List } from '../../components/molucules/custom-react-admin';
import { Datagrid, DateField, ShowButton, TextField, WrapperField } from 'react-admin';
import { Box } from '@mui/material';
import { CommonButton } from '../../components/atoms/button/common-button';

export const UnsettledBalanceHistoriesList = (props: { children?: ReactNode }) => {
  return (
    <List {...props} title={'収益金計上管理'} hasCreate={false}>
      <>
        <Box className={'listTitle'}>
          <Box className={'listTitleButton'} mt={1}>
            <CommonButton text={'収益を計上する'} className={'listButtonCommon buttonNoIcon'} />
          </Box>
          <Box sx={{ overflow: 'auto' }} />
        </Box>
        <Datagrid bulkActionButtons={<></>}>
          <TextField source="id" label="注文ID" sortable={false} />
          <TextField source="ordererName" label="注文者名" sortable={false} />
          <TextField source="exhibitorName" label="出品者名" sortable={false} />
          <TextField source="artworkName" label="作品名" sortable={false} />
          <TextField source="rentalPlan" label="プラン" sortable={false} />
          <DateField source="orderAt" label="注文日" sortable={false} locales={'ja-jp'} showTime />
          <DateField source="shipAt" label="発送日" sortable={false} locales={'ja-jp'} showTime />
          <DateField source="completeAt" label="到着日" sortable={false} locales={'ja-jp'} showTime />
          <TextField source="action" label="状態" sortable={false} />
          <DateField source="statusUpdatedAt" label="状態更新日" sortable={false} locales={'ja-jp'} showTime />
          <TextField source="revenue" label="収益計上顆" sortable={false} />
          <DateField source="lastAccountingDate" label="前回計上日" sortable={false} locales={'ja-jp'} showTime />
          <WrapperField label={'操作'}>
            <ShowButton className={'buttonNoIcon'} label={'詳細'} />
          </WrapperField>
        </Datagrid>
      </>
    </List>
  );
};
