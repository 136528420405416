import { printf } from '../../../../util/function';

const lang = {
  default: '{field}無効な形式です',
  required: '{field}は必須項目です',
  requiredIf: '{field}は必須項目です',
  email: '{field}無効な形式です',
  phone: '{field}無効な形式です',
  image: '{field}無効な形式です',
  nameKana: '{field}無効な形式です',
  max: '{field}は{length}文字以内にしてください',
  min: '{field}は{length}文字以上でなければなりません。',
};

const getMessage = (path: string, obj: Record<any, any>, defaultValue?: string) => {
  return path.split('.').reduce((o, p) => (o && o[p] ? o[p] : defaultValue), obj);
};

const errorMessage = (key: string, field: string | Record<string, string>) => {
  const message = getMessage(key, lang);
  if (typeof message !== 'string') {
    return printf(lang.default, typeof field === 'object' ? field : { field: field });
  }

  return printf(message, typeof field === 'object' ? field : { field: field });
};

export default errorMessage;
