import * as React from 'react';
import { IFormInputValue, RecordInfo, Show } from '../../components/molucules/custom-react-admin';
import { Box } from '@mui/material';
import { FRONT_BASE_URL } from 'src/util/constant/api-url';

const attrs: IFormInputValue[] = [
  {
    label: 'アーティストID',
    name: 'id',
    render: (record) => {
      return (
        <a href={`${FRONT_BASE_URL}artist/${record.id}`} target={'_blank'}>
          {record.id}
        </a>
      );
    },
  },
  { label: 'アーティスト名', name: 'artistName' },
  { label: 'アイコン画像', name: 'iconPath', type: 'image' },
  { label: 'ホームページURL', name: 'webSite' },
  {
    label: 'TwitterURL',
    name: 'Twitter',
    render: (record) => {
      return record['twitter'] ? 'https://www.twitter.com/' + record['twitter'] : '';
    },
  },
  {
    label: 'InstagramURL',
    name: 'Instagram',
    render: (record) => {
      return record['instagram'] ? 'https://www.instagram.com/' + record['instagram'] : '';
    },
  },
  {
    label: 'FacebookURL',
    name: 'Facebook',
    render: (record) => {
      return record['facebook'] ? 'https://www.facebook.com/' + record['facebook'] : '';
    },
  },
  { label: 'プロフィール', name: 'profile' },
  {
    label: '受賞歴・入選歴',
    name: 'awardList',
    render: (record) => {
      const awardList = record.careerList?.filter((career: any) => {
        return career.careerGenre === '受賞歴・入選歴';
      });

      return (
        <>
          {awardList?.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div>
                  {item.year} <span style={{ paddingLeft: '20px' }} />
                  {item.actualResultName}
                </div>
              </React.Fragment>
            );
          })}
        </>
      );
    },
  },
  {
    label: '個展',
    name: 'soloExhibition',
    render: (record) => {
      const awardList = record.careerList?.filter((career: any) => {
        return career.careerGenre === '個展';
      });

      return (
        <>
          {awardList?.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div>
                  {item.year} <span style={{ paddingLeft: '20px' }} />
                  {item.actualResultName}
                </div>
              </React.Fragment>
            );
          })}
        </>
      );
    },
  },
  {
    label: '主なグループ展',
    name: 'groupExhibition',
    render: (record) => {
      const awardList = record.careerList?.filter((career: any) => {
        return career.careerGenre === '主なグループ展';
      });

      return (
        <>
          {awardList?.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div>
                  {item.year} <span style={{ paddingLeft: '20px' }} />
                  {item.actualResultName}
                </div>
              </React.Fragment>
            );
          })}
        </>
      );
    },
  },
  { label: 'HHID', name: 'hhId' },
];

/*
const WithdrawDetailButton = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  return (
    <Box
      sx={{
        display: 'inline-block',
        '& a': {
          textDecoration: 'none',
        },
      }}
    >
      <Link to={createPath({ resource: 'balance-histories/account/' + record.accountId, type: 'list' })}>
        <CommonButton text={'出金履歴詳細'} variant={'black'} />
      </Link>
    </Box>
  );
};
*/

export const ArtistShow = () => {
  return (
    <Show recordTitleAttribute={'id'} hasEdit={false} buttons={<></>}>
      <Box sx={styles}>
        <RecordInfo data={attrs} />
      </Box>
    </Show>
  );
};

const styles = {
  '& .transferBox': {
    margin: '50px 30px 0 0',
    '& .formGroupLabel ': {
      '& label': {
        color: '#000 !important',
      },
    },
  },
};
