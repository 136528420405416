import { ListItemIcon } from '@mui/material';
import { UnsettledBalanceHistoriesList } from './list';
import { UnsettledBalanceHistoriesShow } from './show';
import { UnsettledBalanceHistoriesEdit } from './edit';
//

export default {
  menuIcon: ListItemIcon,
  options: { label: '収益金計上管理' },
  list: UnsettledBalanceHistoriesList,
  show: UnsettledBalanceHistoriesShow,
  edit: UnsettledBalanceHistoriesEdit,
};
