import { CompositeDecorator } from 'draft-js';

function isTargetEntityType(entityType: string) {
  return entityType.toLowerCase() === 'link';
}

export const CustomDecorator = new CompositeDecorator([
  {
    strategy: (block, callback, contentState) => {
      block.findEntityRanges((characterMetadata) => {
        const entityKey = characterMetadata.getEntity();
        return Boolean(entityKey) && isTargetEntityType(contentState.getEntity(entityKey).getType());
      }, callback);
    },
    component: ({ ...props }) => {
      const entity = props.contentState.getEntity(props.entityKey);
      const entityType = entity.getType().toLowerCase();

      if (entityType === 'link') {
        const { url } = entity.getData();
        return (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        );
      }

      // フォールバック
      return null;
    },
  },
]);
