import * as React from 'react';
import { HtmlHTMLAttributes, ReactNode, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';

import { LoginForm as DefaultLoginForm } from '../../components/organisms/auth/LoginForm';

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
export const Login = (props: LoginProps) => {
  const { children, backgroundImage, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>();

  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, navigate]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    // @ts-ignore
    <Root {...rest} ref={containerRef}>
      <Card className={LoginClasses.card}>
        <SPageTitle>管理画面ログイン</SPageTitle>
        {children}
      </Card>
    </Root>
  );
};

export interface LoginProps extends HtmlHTMLAttributes<HTMLDivElement> {
  backgroundImage?: string;
  children?: ReactNode;
  className?: string;
  sx?: SxProps;
}

const PREFIX = 'RaLogin';
export const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

  [`& .${LoginClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
  },
  [`& .${LoginClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${LoginClasses.icon}`]: {
    // backgroundColor: theme.palette.secondary[500]
    backgroundColor: '#ddd',
  },
}));

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Login.defaultProps = {
  children: <DefaultLoginForm />,
};

const SPageTitle = styled('h1')({
  fontSize: '20px',
  marginBlockStart: '16px',
  textAlign: 'center',
});

export default Login;
