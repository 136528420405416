import { AtomicBlockUtils, convertFromRaw, EditorState } from 'draft-js';
import type { RawDraftContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { useCallback, useEffect, useState } from 'react';
import { ComponentWithoutChildren } from '../../../types/component';
import { CustomDecorator } from '../decorator';
import { Box, Stack, Typography } from '@mui/material';
import { richTextEditorPlugins, RichTextEditorToolbar } from '../plugins';
import { ImageFileMIME } from '../../../util/files';
import FileDropzone from '../../file-dropzone';
import { imageUploader } from '../../image-uploader';

// プラグインのスタイルを適用
import '../plugins/styles';
import { className } from '../../../util/function';
import { convertFromHtml } from './convert-from-html';
import { convertToHtml } from './convert-to-html';

/**
 * 新規作成時など入力内容がない場合の初期値
 */
const DEFAULT_INITIAL_CONTENT = EditorState.createEmpty(CustomDecorator);

type Props = {
  initialContent?: string | RawDraftContentState;
  onChange: (content: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const RichTextEditor: ComponentWithoutChildren<Props> = ({
  initialContent,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const initEditor = (initialContent?: string | RawDraftContentState) => {
    return !initialContent
      ? DEFAULT_INITIAL_CONTENT
      : EditorState.createWithContent(
          typeof initialContent === 'string' ? convertFromHtml(initialContent) : convertFromRaw(initialContent),
          CustomDecorator,
        );
  };

  const [editorState, setEditorState] = useState(initEditor(initialContent));

  useEffect(() => {
    if (initialContent && convertToHtml(editorState) !== initialContent) {
      handleEditorStateChange(initEditor(initialContent));
    }
    // eslint-disable-next-line
  }, [initialContent]);

  const handleEditorStateChange = useCallback(
    (changedEditorState: EditorState) => {
      setEditorState(changedEditorState);

      let html: string | undefined = convertToHtml(changedEditorState);
      if (html === '<p></p>') {
        html = undefined;
      }

      if (initialContent !== html) {
        onChange(html || '');
      }
    },
    // eslint-disable-next-line
    [onChange],
  );

  const insertImage = useCallback((editorState: EditorState, filePath: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: filePath });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  }, []);

  const handleBlur = () => {
    setIsFocus(false);
    return onBlur;
  };

  const handleFocus = () => {
    setIsFocus(true);
    return onFocus;
  };

  return (
    <Stack className="rich-text-editor" spacing={1}>
      <Box
        className={className({ 'rich-text-editor__textarea': true, isFocused: isFocus })}
        sx={{
          padding: '7px 14px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          img: {
            maxWidth: '100%',
          },
          figure: {
            margin: 0,
          },
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.isFocused': {
            borderColor: '#73879D',
          },
        }}
      >
        <Editor
          editorState={editorState}
          onChange={handleEditorStateChange}
          plugins={richTextEditorPlugins}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <RichTextEditorToolbar />
      </Box>

      <Box>
        <FileDropzone
          disabled={isUploadingFile}
          label={
            <Typography fontSize="0.75rem" color="rgba(0, 0, 0, 0.6)" textAlign="center">
              エディタ中に画像ファイルを貼付する
              <br />
              画像ファイルをアップロード (PNG・JPEG)
            </Typography>
          }
          dropAction={async (file) => {
            if (!file) return;
            setIsUploadingFile(true);

            try {
              const resFilePath = await imageUploader(file);
              handleEditorStateChange(insertImage(editorState, resFilePath));
            } catch (error) {
              console.error(error);
            }

            setIsUploadingFile(false);
          }}
          accept={[ImageFileMIME.png, ImageFileMIME.jpeg]}
        />
      </Box>
    </Stack>
  );
};

export default RichTextEditor;
