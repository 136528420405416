import React, { ReactNode } from 'react';
import { Box, InputLabel } from '@mui/material';
import RequiredLabel from '../../../atoms/form/required-label';
import { className } from '../../../../util/function';
import { LABEL_WIDTH } from '../../../../util/constant/form';

type Props = {
  required?: boolean;
  label?: string;
  labelNote?: string;
  children: ReactNode;
  row?: boolean;
};
const styles = {
  display: 'flex',
  marginTop: '15px',
  flexDirection: 'column',
  '&.hasLabel': {
    justifyContent: 'center',
    '& .formGroupInput': {
      width: { xs: '100%', sm: `calc(100% - ${LABEL_WIDTH})` },
      '& > *': {
        width: 'auto',
      },
    },
  },
  '&.row': {
    flexDirection: { xs: 'column', sm: 'row' },
    '& .formGroupInput': {
      alignItems: { xs: 'flex-start', md: 'flex-start' },
    },
  },
  '& .row': { flexDirection: 'row' },
  '& .formGroupLabel': {
    display: 'flex',
    width: '100%',
    maxWidth: { xs: '100%', sm: LABEL_WIDTH },
    textAlign: 'left',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '14px',
    '& .MuiFormLabel-root': {
      color: '#73879D',
      fontWeight: '600',
      whiteSpace: 'inherit',
    },
  },
  '& .formGroupInput': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: { sm: 0, xs: '10px' },
    width: '100%',
    color: '#73879D',
    '& > *': {
      width: '100%',
    },
  },
  '& .formGroupLabelNote': { fontSize: '14px', fontWeight: 300 },
};

export const FormGroup = ({ required = false, label, labelNote, children, row = true }: Props): JSX.Element => {
  return (
    <Box sx={styles} className={className({ formGroup: true, row: !!row, hasLabel: !!label })}>
      {label && (
        <Box className={'formGroupLabel'}>
          <InputLabel>
            {label}
            {required && <RequiredLabel />}
            {labelNote && <span className={'formGroupLabelNote'}>{labelNote}</span>}
          </InputLabel>
        </Box>
      )}
      <Box className={'formGroupInput'}>{children}</Box>
    </Box>
  );
};
