import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createImagePlugin from '@draft-js-plugins/image';
import createFocusPlugin from '@draft-js-plugins/focus';
import createResizablePlugin from '@draft-js-plugins/resizeable';
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  OrderedListButton,
  UnorderedListButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from '@draft-js-plugins/buttons';
import { composeDecorators } from '@draft-js-plugins/editor';
import { getFileUrl } from '../../../util/function';

const inlineToolbarPlugin = createInlineToolbarPlugin();

const linkPlugin = createLinkPlugin({
  placeholder: 'https://...',
});

const focusPlugin = createFocusPlugin();

const resizablePlugin = createResizablePlugin();

const blockDndPlugin = createBlockDndPlugin();

const imagePlugin = createImagePlugin({
  decorator: composeDecorators(focusPlugin.decorator, blockDndPlugin.decorator, resizablePlugin.decorator),
  imageComponent: (props) => {
    console.log(props);
    const {
      resizeData: { src },
    } = props.blockProps as { resizeData: { src: string } };
    return <img src={getFileUrl(src)} alt={src} style={props.style} className={props.className} />;
  },
});

export const richTextEditorPlugins = [
  inlineToolbarPlugin,
  linkPlugin,
  imagePlugin,
  blockDndPlugin,
  focusPlugin,
  resizablePlugin,
];

export const RichTextEditorToolbar = () => {
  return (
    <inlineToolbarPlugin.InlineToolbar>
      {(externalProps) => (
        <>
          <BoldButton {...externalProps} />
          <ItalicButton {...externalProps} />
          <UnderlineButton {...externalProps} />
          <linkPlugin.LinkButton {...externalProps} />
          <HeadlineOneButton {...externalProps} />
          <HeadlineTwoButton {...externalProps} />
          <HeadlineThreeButton {...externalProps} />
          <UnorderedListButton {...externalProps} />
          <OrderedListButton {...externalProps} />
        </>
      )}
    </inlineToolbarPlugin.InlineToolbar>
  );
};
