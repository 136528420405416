type Credential = { email: string; password: string };
const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';
const loginApiUrl = process.env.REACT_APP_ENDPOINT_PATH_LOGIN || '/login';
const LOGIN_ENDPOINT = baseApiUrl + loginApiUrl;
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// 認証プロバイダ
// [参考]
// https://marmelab.com/react-admin/AuthProviderWriting.html
// https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html
export const authProvider = {
  login: (props: Credential) => {
    return fetch(LOGIN_ENDPOINT, { method: 'POST', headers, body: JSON.stringify(props) })
      .then((response: Response) => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
        return response.json();
      })
      .then((resBody) => {
        const { data } = resBody;
        console.log(data);
        localStorage.setItem('auth', JSON.stringify(data));
      })
      .catch(() => {
        throw new Error('ネットワークエラー');
      });
  },

  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },

  // called when the API returns an error
  checkError: (status: number) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
  },

  getIdentity: () => {
    try {
      const authParams = localStorage?.getItem('auth') || '';
      const { id, email } = JSON.parse(authParams);
      return Promise.resolve({ id, fullName: email });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};
