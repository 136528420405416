import { downloadCSV, Exporter } from 'react-admin';
// @ts-ignore
import jsonExport from 'jsonexport/dist';

type HeaderType = string | number;

// https://github.com/kaue/jsonexport#options
type CommonExporterType = (jsonExportOptions?: {
  headers?: HeaderType[];
  rename?: HeaderType[];
  transformData?: (data: any) => any;
}) => Exporter;

export const CommonExporter: CommonExporterType = (jsonExportOptions = {}) => {
  const defaultExporter: Exporter = (data, _, __, resource) => {
    const { headers, transformData } = jsonExportOptions;
    const renderData = headers?.length
      ? data.map((item: any) => {
          item = transformData ? transformData(item) : item;

          const renderItem: any = {};
          for (const attr in item) {
            if (headers.includes(attr)) {
              renderItem[attr] = item[attr];
            }
          }

          return renderItem;
        })
      : data;
    jsonExport(renderData, jsonExportOptions, (err: any, csv: any) => downloadCSV(csv, resource));
  };

  return defaultExporter;
};
