import {
  BulkExportButton,
  CreateButton,
  ExportButton,
  Exporter,
  ListBase,
  Pagination,
  SortPayload,
  Title,
} from 'react-admin';
import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { FilterForm } from './filter-form';
import { FilterFormInputProps } from './filter-form-input';
import { UseQueryOptions } from 'react-query';

type ListBaseProps = {
  debounce?: number;
  disableAuthentication?: boolean;
  disableSyncWithLocation?: boolean;
  exporter?: Exporter | false;
  perPage?: number;
  resource?: string;
  sort?: SortPayload;
  queryOptions?: UseQueryOptions<{
    data: any[];
    total?: number;
    pageInfo?: {
      hasNextPage?: boolean;
      hasPreviousPage?: boolean;
    };
  }> & { meta?: any };
};

type Props = ListBaseProps & {
  children: ReactNode;
  title?: string | ReactElement;
  filters?: FilterFormInputProps[];
  filterDefaultValues?: object;
  hasCreate?: boolean;
  hasExport?: boolean;
  hasBulkExport?: boolean;
};

export const List = ({
  children,
  //actions,
  filters,
  filterDefaultValues,
  title,
  hasCreate = true,
  hasExport = false,
  hasBulkExport = false,
  ...props
}: Props) => {
  return (
    <Box sx={styles} className={'listBase'}>
      <ListBase {...props} filterDefaultValues={filterDefaultValues}>
        <Title title={title} />
        <Card className={'list'}>
          {title && (
            <Typography variant={'h6'} className={'listTitle'}>
              {title}
              <Box className={'listTitleButton'}>
                {hasCreate && <CreateButton label={'追加'} className={'listButtonCommon buttonNoIcon'} />}
                {hasExport && (
                  <ExportButton label={'csv出力'} className={'listButtonCommon buttonNoIcon'} maxResults={10000000} />
                )}
                {hasBulkExport && <BulkExportButton label={'csv出力'} className={'listButtonCommon buttonNoIcon'} />}
              </Box>
            </Typography>
          )}
          {filters && <FilterForm filters={filters} defaultValues={filterDefaultValues} />}
          {children}
          <Pagination rowsPerPageOptions={[10, 20, 40, 60, 80, 100]} className={'pagination'} />
        </Card>
      </ListBase>
    </Box>
  );
};

const styles = {
  borderRadius: 0,
  padding: '50px 25px',
  color: '#73879D',
  '& .list': {
    padding: '20px',
  },
  '& .listTitle': {
    paddingBottom: '10px',
    borderBottom: '1px solid #ccc',
    color: '#73879D',
    '&:after': {
      content: '""',
      clear: 'both',
      display: 'table',
    },
    '& .listTitleButton': {
      display: 'inline-block',
      float: 'right',
      '& .listButtonCommon': {
        background: '#5CB85C',
        borderColor: `#5CB85C !important`,
        color: '#FFF',
        width: 'auto',
        borderRadius: '6px',
        boxShadow: 'none',
        border: '1px solid',
        padding: '6px 10px',
        minWidth: '50px',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1rem',
        marginLeft: '15px',
        '&:hover': {
          background: '#5CB85C',
          borderColor: `#5CB85C !important`,
          color: '#FFF',
          opacity: 0.9,
        },
      },
    },
  },
  // list
  '& .RaDatagrid-tableWrapper': {
    margin: '25px 0 0',
    borderRadius: '0',
    border: '1px solid #ccc',
    borderBottom: 0,
    color: '#73879D',
    width: '100%',
    overflowX: 'auto',

    '& .RaDatagrid-thead': {
      minWidth: '50px',
      '& .RaDatagrid-headerCell': {
        background: '#405467',
        color: '#FFF',
        textAlign: 'left',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        position: 'relative',
        '&:last-child': {
          borderRight: '0',
        },
        '& .MuiTableSortLabel-root, .MuiTableSortLabel-icon': {
          color: '#FFF',
        },
        '& .MuiTableSortLabel-root': {
          position: 'initial',
          display: 'inline-block',
          width: '100%',
        },
        '& .MuiTableSortLabel-icon': {
          position: 'absolute',
          right: '0',
          top: '50%',
          transform: 'translateY(-50%)',
          '&.MuiTableSortLabel-iconDirectionAsc': {
            transform: 'translateY(-50%) rotate(180deg)',
          },
        },
      },
      '& .RaDatagrid-headerCell:first-of-type': {
        borderRadius: 0,
      },
      '& .RaDatagrid-headerCell:last-child': {
        borderRadius: 0,
      },
    },

    '& .RaDatagrid-tbody': {
      '& .RaDatagrid-rowEven': {
        background: '#F8F8F8',
      },
      '& .MuiTableCell-root': {
        color: '#73879D',
        wordBreak: 'break-all',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        whiteSpace: 'pre-wrap',
        '&:last-child': {
          borderRight: '0',
        },
      },

      '& img': {
        width: '100%',
        height: 'auto',
        maxWidth: '200px',
      },
    },
  },

  '& .buttonNoIcon': {
    '& .MuiButton-startIcon': {
      display: 'none',
    },
  },
  // pagination
  '& .MuiTablePagination-root': {
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
    '& .MuiTablePagination-displayedRows': {
      display: 'none',
    },
    '& .MuiTablePagination-toolbar': {
      justifyContent: 'center',
      padding: '10px 0',
    },
    '& .MuiPaginationItem-root': {
      padding: '2px',
      border: '1px solid #ccc',
      borderRadius: 0,
      margin: '0 2px',
      height: '32px',
      minWidth: '28px',
      '&.Mui-selected': {
        background: '#444444',
        color: '#FFF',
      },
    },
  },

  // bulk action
  '& .RaBulkActionsToolbar-toolbar': {
    display: 'none',
  },
};
