import { ListItemIcon } from '@mui/material';
import { PurchaseHistoryShow } from './show';
import { PurchaseHistoryList } from './list';

export default {
  list: PurchaseHistoryList,
  menuIcon: ListItemIcon,
  show: PurchaseHistoryShow,
  options: { label: '購入管理' },
};
