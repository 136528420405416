import React, { FormHTMLAttributes, FunctionComponent } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useVForm } from '../../form';
import { Box } from '@mui/material';

const styles = {
  '& .MuiTextField-root': {
    '& .MuiInputLabel-root': {
      '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
        color: 'transparent',
        // these are the classes used from material-ui library for the asterisk element
        '&::after': {
          content: '"*"', // add your text here
          marginLeft: '-5px',
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
      '&.Mui-error': {
        '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
          '&::after': {
            color: '#d32f2f',
          },
        },
      },
      '&.Mui-focused': {
        '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
          '&::after': {
            color: '#1976d2',
          },
        },
      },
    },
  },
};

export type VFormContainerProps = {
  defaultValues?: any;
  onSuccess?: (values: any) => void;
  onError?: (values: any) => void;
  handleSubmit?: (values: any) => void;
  formContext?: UseFormReturn<any>;
  FormProps?: FormHTMLAttributes<HTMLFormElement>;
  children: React.ReactNode;
};

const VFormCore: FunctionComponent<VFormContainerProps> = ({
  defaultValues = {},
  onSuccess = () => {},
  onError = () => {},
  FormProps,
  children,
}) => {
  const methods = useVForm<typeof defaultValues>({
    defaultValues,
  });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSuccess, onError)} noValidate {...FormProps}>
        <Box sx={styles}>{children}</Box>
      </form>
    </FormProvider>
  );
};
const VFormContainer: FunctionComponent<VFormContainerProps> = (props) => {
  const className: string = props.FormProps?.className || '';

  if (!props.formContext && !props.handleSubmit) {
    return <VFormCore {...props} />;
  } else if (props.handleSubmit && props.formContext) {
    return (
      <FormProvider {...props.formContext}>
        <form noValidate className={className} {...props.FormProps} onSubmit={props.handleSubmit}>
          <Box sx={styles}>{props.children}</Box>
        </form>
      </FormProvider>
    );
  }
  if (props.formContext && props.onSuccess) {
    return (
      <FormProvider {...props.formContext}>
        <form
          className={className}
          onSubmit={props.formContext.handleSubmit(props.onSuccess, props.onError)}
          noValidate
          {...props.FormProps}
        >
          <Box sx={styles}>{props.children}</Box>
        </form>
      </FormProvider>
    );
  }

  return <div>Incomplete setup of FormContainer..</div>;
};

export default VFormContainer;
