import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';

export default function useVForm<TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> {
  if (props) {
    props.mode = props.mode || 'all';
  }

  // @ts-ignore
  return useForm({ ...props });
}
