import { FormGroup } from '../../atoms/form';
import { FormInputValue, IFormInputValue } from './index';
import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';

type props = {
  data: IFormInputValue[];
};

export const RecordInfo = ({ data }: props) => {
  const record = useRecordContext();

  return (
    <>
      {data.map((attr) => {
        return (
          <Box className={attr.className} key={attr.name}>
            <FormGroup label={attr.renderLabel ? attr.renderLabel(record) : attr.label}>
              <FormInputValue {...attr} record={record} />
            </FormGroup>
          </Box>
        );
      })}
    </>
  );
};
