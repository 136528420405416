import * as React from 'react';
import { createElement } from 'react';
import { AppBar, AppBarProps, Layout, Logout, Menu, MenuItemLink, useResourceDefinitions, UserMenu } from 'react-admin';
import { LayoutProps } from 'ra-ui-materialui';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Logo } from '../atoms/logo';
import { useCreatePath, useGetResourceLabel } from 'ra-core';
import DefaultIcon from '@mui/icons-material/ViewList';
import { useLogout } from 'react-admin';

export const MyLayout = (props: LayoutProps): JSX.Element => {
  return (
    <>
      <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const CustomAppBar = (props: AppBarProps): JSX.Element => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <AppBar {...props} color="secondary" position={'fixed'} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />
      {isLargeEnough && (
        <Box
          sx={{
            flex: 1,
            textAlign: 'center',
            '& svg': {
              width: '112px',
              height: '20px',
              '@media (min-width:600px)': {
                width: '160px',
                height: '17.5px',
              },
            },
          }}
        >
          <Logo />
        </Box>
      )}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

const CustomUserMenu = () => (
  <UserMenu>
    <Logout />
  </UserMenu>
);

const CustomMenu = () => {
  const resources = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const logout = useLogout();
  const handleLogoutClick = () => logout();
  return (
    <Menu>
      {/*<MenuItemLink to={`${basename}/`} primaryText={'トップページ'} leftIcon={<DashboardIcon />} />*/}
      {Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => (
          <MenuItemLink
            key={name}
            to={createPath({
              resource: name,
              type: 'list',
            })}
            state={{ _scrollToTop: true }}
            primaryText={getResourceLabel(name, 2)}
            leftIcon={resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />}
          />
        ))}
      <MenuItemLink to={'#'} primaryText={'ログアウト'} onClick={handleLogoutClick} leftIcon={<DefaultIcon />} />
      {/*<Menu.Item to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />} />*/}
    </Menu>
  );
};
