import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Box, FormHelperText, IconButton, LinearProgress, Typography } from '@mui/material';
import { Control, Controller, ControllerProps, FieldError, useFormContext } from 'react-hook-form';
import transformValidation from '../common/validation';
import AddIcon from '@mui/icons-material/Add';
import useUniqueId from '../../../../hooks/useUniqueId';
import { className, getFileUrl } from '../../../../util/function';
import CloseIcon from '@mui/icons-material/Close';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import errorMessage from '../common/message';
import { axiosBackendClient } from '../../../../util/axios';
import { API_UPLOAD_IMAGE } from '../../../../util/constant/api-url';

const styles = {
  display: 'inline-block',
  verticalAlign: 'top',
  '& .isUploading': {
    opacity: 0.5,
  },
  '& .flex': {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .addIcon': { fontSize: '4rem' },
  '& .hidden': { display: 'none' },
  '& .boxWrapper': {
    border: 1,
    borderStyle: 'dashed',
    borderColor: '#999',
    margin: '0 auto',
    position: 'relative',
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    '&.invalid': {
      borderColor: 'error.main',
    },
    '& .boxWrapperPreview': {
      height: 'auto',
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '& video,img': {
      maxWidth: '100%',
      height: 'auto',
      float: 'left',
    },
    '& .closeButton': {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '2px',
    },
    '& .reUploadButton': {
      position: 'absolute',
      top: 0,
      left: 0,
      color: '#000',
      padding: '2px',
    },
  },
  '& .previewWrapper': {
    position: 'relative',
    width: '100%',
    height: '100%',
    '&:after': {
      content: '""',
      display: 'block',
      clear: 'both',
    },
    '& .progress': {
      position: 'absolute',
      bottom: '5px',
      left: '10px',
      right: '10px',
    },
  },
  '& .vFileUploadTitle': {
    fontSize: '0.875rem',
    color: '#999999',
  },
  '& .vFileUploadLabel': {
    fontSize: '0.75rem',
  },
};

export type VFileUploadRef = {
  removeFile: () => void;
  click: () => void;
};

export type VFileUploadProps = {
  validation?: ControllerProps['rules'];
  name: string;
  parseError?: (error: FieldError) => string;
  control?: Control<any>;
  onError?: (error: FieldError | undefined) => void;
  errorLabel?: string;
  required?: boolean;
  preview?: boolean;
  previewRender?: (url?: string, file?: File) => React.ReactNode;
  previewUrl?: string;
  accept?: string;
  label?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement>, url?: string) => void;
  onDrop?: (event: React.DragEvent<HTMLElement>) => void;
  showRemoveButton?: boolean;
  isAutoUpload?: boolean;
  title?: string;
  uploadUrl?: string;
};

function VFileUpload(
  {
    name,
    validation = {},
    parseError,
    required,
    control,
    preview = true,
    previewRender,
    previewUrl,
    accept = 'image/jpg, image/jpeg, image/png',
    label = '画像を追加',
    errorLabel,
    width = '150px',
    height = '150px',
    backgroundColor = '#F5F5F5',
    showRemoveButton = true,
    isAutoUpload = true,
    title,
    uploadUrl = API_UPLOAD_IMAGE,
    ...rest
  }: VFileUploadProps,
  ref: any,
) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<any>(null);

  const resetUploadState = () => {
    setUploadError(null);
    setIsUploading(false);
  };

  // @ts-ignore
  const innerValidation = transformValidation(validation, errorLabel || label, required);
  if (innerValidation) {
    innerValidation.validate = {
      ...(innerValidation.validate || {}),
      vFileUploadCheckUploadState: () => {
        if (isUploading) {
          return errorMessage('isUploading', errorLabel || label || 'ファイル');
        }
        if (uploadError) {
          return errorMessage('uploadError', errorLabel || label || 'ファイル');
        }
        return true;
      },
    };
  }

  const [progress, setProgress] = useState(0);
  const { setValue, getValues, trigger } = useFormContext();
  const [url, setUrl] = React.useState(previewUrl || undefined);
  const [file, setFile] = React.useState<File | undefined>(undefined);
  const [labelText, setLabelText] = React.useState(label);
  const fileValue = getValues(name);
  const fileInputRef = React.createRef<HTMLInputElement>();

  const render = (file: File) => {
    if (preview) {
      url && URL.revokeObjectURL(url);
      setUrl(URL.createObjectURL(file));
    }
    setLabelText(file.name);
    setFile(file);
  };

  const renderByUrl = (url: string) => {
    const fullUrl = getFileUrl(url);
    if (preview) {
      setUrl(fullUrl);
    }
    setLabelText(fullUrl);
  };

  // update file state from formContext if rerender form
  if (!file && !url && fileValue) {
    if (fileValue[0] instanceof File) {
      render(fileValue[0]);
    } else {
      renderByUrl(fileValue);
    }
  }

  useEffect(() => {
    setLabelText(label);
  }, [label]);

  const id = useUniqueId('v-file-upload');

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('files', file);

    let response = null;
    let error = null;
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('files', file);
      response = await axiosBackendClient.post<ResponseType>(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {
      error = e;
    }

    setIsUploading(false);
    setProgress(0);
    setUploadError(error);
    error && trigger(name);

    console.log(response);
    return response?.data?.[0] ? response.data[0] : null;
  };

  function getFileType(file: File) {
    if (file.type.match('image.*')) return 'image';

    if (file.type.match('video.*')) return 'video';

    if (file.type.match('audio.*')) return 'audio';

    return 'other';
  }

  const renderDefaultBox = () => {
    return (
      <Box height={height} width={width} className={'flex'}>
        <Box textAlign={'center'}>
          <Typography className={'vFileUploadTitle'}>{title}</Typography>
          <AddIcon className={'addIcon'} />
          <Typography className={'vFileUploadLabel'}>{labelText}</Typography>
        </Box>
      </Box>
    );
  };

  const renderPreview = (url?: string, file?: File) => {
    if (previewRender) {
      return previewRender(url, file);
    } else {
      let type = '';
      let defaultType = false;
      if (file) {
        type = getFileType(file);
        defaultType = !(type === 'image' || type === 'video');
      } else if (url) {
        type = 'image';
      }

      return (
        <Box className={className({ isUploading: isUploading, previewWrapper: true })}>
          {type === 'image' && <img alt="file upload" src={url || ''} />}
          {type === 'video' && <video src={url} />}
          {defaultType && renderDefaultBox()}
          {isUploading && progress < 100 && (
            <LinearProgress variant="determinate" value={progress} className={'progress'} />
          )}
        </Box>
      );
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.files?.[0]) {
      render(event.target.files[0]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const removeFile = (fileInput?: React.RefObject<HTMLInputElement>) => {
    setValue(name, null);
    setUrl('');
    setFile(undefined);
    setLabelText(label);
    resetUploadState();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setTimeout(() => {
      trigger(name);
    }, 0);
  };

  useImperativeHandle(ref, () => ({
    removeFile: () => {
      removeFile();
    },
    click: () => {
      fileInputRef.current?.click();
    },
  }));

  const handleClickRemove = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    removeFile();
  };

  const handleClickReUpload = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isUploading || !file) return;

    const url = await uploadFile(file);
    if (url) {
      setValue(name, url);
      rest.onChange && rest.onChange(e, url);
    }
  };

  const handleClickFileUpload = (e: React.MouseEvent<HTMLElement>) => {
    if (isUploading) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={innerValidation}
      render={({ field: { onChange }, fieldState: { invalid, error } }) => {
        rest.onError && rest.onError(error);

        return (
          <>
            <Box sx={styles} className={'vFileUpload'}>
              <Box className={'hidden'}>
                <input
                  onChange={async (e) => {
                    const files = e.target.files;
                    if (files?.length) {
                      resetUploadState();
                      // file change
                      handleChange(e);
                      await onChange(files);

                      const valid = await trigger(name);
                      if (valid && isAutoUpload) {
                        // file is validated
                        const url = await uploadFile(files[0]);
                        url && (await onChange(url));
                        url && rest.onChange && (await rest.onChange(e, url));
                        setTimeout(() => {
                          trigger(name);
                        }, 0);
                      } else {
                        rest.onChange && rest.onChange(e);
                      }
                    }
                  }}
                  required={required}
                  accept={accept}
                  id={id}
                  type="file"
                  name={name}
                  ref={fileInputRef}
                />
              </Box>
              <label htmlFor={id} onClick={handleClickFileUpload}>
                <Box
                  className={className({ boxWrapper: true, invalid: invalid, validateError: invalid, relative: true })}
                >
                  <Box className={'boxWrapperPreview'} bgcolor={preview && (url || file) ? '' : backgroundColor}>
                    {preview && (url || file) ? renderPreview(url, file) : renderDefaultBox()}
                  </Box>
                  {showRemoveButton && (url || file) && (
                    <IconButton aria-label="close" className={'closeButton'} onClick={handleClickRemove}>
                      <CloseIcon />
                    </IconButton>
                  )}
                  {uploadError && (
                    <IconButton aria-label="reupload" className={'reUploadButton'} onClick={handleClickReUpload}>
                      <FileUploadIcon />
                    </IconButton>
                  )}
                </Box>
              </label>
              {error && (
                <FormHelperText error={true} sx={{ textAlign: 'center' }}>
                  {typeof parseError === 'function' ? parseError(error) : error.message}
                </FormHelperText>
              )}
            </Box>
          </>
        );
      }}
    />
  );
}

export default forwardRef(VFileUpload);
