import React from 'react';
import { SvgIcon } from '@mui/material';

interface ICheckBoxIcon {
  color?: string;
  border?: string;
}

export default function Checkbox({ color = '#fff', border = '#999' }: ICheckBoxIcon): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 25 25'}>
      <g fill={color} stroke={border} strokeWidth="1">
        <rect width="25" height="25" stroke="none" />
        <rect x="0.5" y="0.5" width="24" height="24" fill="none" />
      </g>
    </SvgIcon>
  );
}
