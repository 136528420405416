import React, { useState } from 'react';
import { FormControlLabelProps, FormHelperText, Grid, InputLabel, TextFieldProps } from '@mui/material';
import { Control, Controller, ControllerProps, FieldError } from 'react-hook-form';
import transformValidation from '../common/validation';
import RequiredLabel from '../required-label';
import { FORM_GRID, LABEL_STYLE } from '../../../../util/constant/form';
import { className } from '../../../../util/function';

// プラグインのスタイルを適用
import '../../../../modules/rich-text/plugins/styles';
import RichTextEditor from '../../../../modules/rich-text/editor';

export type VRichTextEditorProps = Omit<TextFieldProps, 'name'> & {
  validation?: ControllerProps['rules'];
  name: string;
  parseError?: (error: FieldError) => string;
  control?: Control<any>;
  onError?: (error: FieldError | undefined) => void;
  errorLabel?: FormControlLabelProps['label'];
  showRequiredText?: boolean;
  preventNegativeNumber?: boolean;
  preventDot?: boolean;
};

export default function VRichTextEditor({
  validation = {},
  parseError,
  required,
  name,
  control,
  showRequiredText = true,
  label,
  errorLabel,
  ...rest
}: VRichTextEditorProps): JSX.Element {
  // @ts-ignore
  validation = transformValidation(validation, errorLabel || label, required);
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        rest.onError && rest.onError(error);

        return (
          <Grid container className={className({ hasLabel: !!label, formGridContainer: true })} sx={FORM_GRID}>
            {label && (
              <Grid item className={'formGridLabel'}>
                <InputLabel focused={isFocus} error={!!error} sx={LABEL_STYLE}>
                  {label} {required && showRequiredText ? <RequiredLabel /> : ''}
                </InputLabel>
              </Grid>
            )}
            <Grid item className={'formGridInput'}>
              <RichTextEditor
                initialContent={value}
                onChange={(content) => {
                  return onChange(content);
                }}
                onFocus={() => {
                  setIsFocus(true);
                }}
                onBlur={() => {
                  setIsFocus(false);
                }}
              />
              {error && (
                <FormHelperText error={true}>
                  {typeof parseError === 'function' ? parseError(error) : error.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
