import { ListItemIcon } from '@mui/material';
import { ChatList } from './list';
import { ChatShow } from './show';

export default {
  list: ChatList,
  menuIcon: ListItemIcon,
  show: ChatShow,
  options: { label: 'チャット管理' },
};
