import { ArtworkList } from './list';
import { ListItemIcon } from '@mui/material';
import { ArtworkShow } from './show';

export default {
  list: ArtworkList,
  menuIcon: ListItemIcon,
  show: ArtworkShow,
  options: { label: '作品管理' },
};
