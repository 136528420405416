import * as React from 'react';
import { getFileUrl, numberFormat } from '../../../util/function';
import { Box } from '@mui/material';
import { Image } from '../../atoms/image';
import get from 'lodash/get';

type InputType =
  | 'text'
  | 'number'
  | 'image'
  | 'boolean'
  | 'array'
  | 'arrayObject'
  | 'datetime'
  | 'date'
  | 'postCode'
  | 'richText'
  | 'select'
  | 'radio'
  | 'checkbox'
  | 'year';

export type IFormInputValue = {
  name: string;
  label: string;
  renderLabel?: (record: any) => string;
  type?: InputType;
  render?: (record: any) => React.ReactNode;
  arrayObjectAttr?: string;
  className?: string;
  options?: any[];
};

type FormInputValueProps = IFormInputValue & {
  record: any;
};

const Root = ({ type = 'default', children }: { type?: string; children: React.ReactNode }) => {
  return (
    <Box className={'formInputValue formInputValue-' + type} sx={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>
      {children}
    </Box>
  );
};

export const FormInputValue = ({ record, ...attr }: FormInputValueProps): JSX.Element => {
  if (attr.render) {
    return <Root type={'render'}>{attr.render(record)}</Root>;
  }

  const value = get(record, attr.name);
  if (typeof value === 'undefined' || value === null) {
    return (
      <Root>
        <></>
      </Root>
    );
  }

  const type = attr.type || 'text';

  if (type === 'image') {
    if (Array.isArray(value)) {
      return (
        <Root type={'image'}>
          {value.map((src) => {
            return (
              <>
                {src && (
                  <Box>
                    <Image src={getFileUrl(src)} maxWidth={200} />
                  </Box>
                )}
              </>
            );
          })}
        </Root>
      );
    } else {
      return (
        <Root type={'image'}>
          <Image src={getFileUrl(value)} maxWidth={200} />
        </Root>
      );
    }
  }

  if (type === 'number') {
    return <Root type={'number'}>{numberFormat(value)}</Root>;
  }

  if (type === 'boolean') {
    return <Root type={'boolean'}>{value ? 'はい' : 'いいえ'}</Root>;
  }

  if (type === 'array') {
    return (
      <Root type={'array'}>
        {value.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          );
        })}
      </Root>
    );
  }

  if (type === 'arrayObject') {
    if (!attr.arrayObjectAttr) {
      throw new Error('FormInputValue type arrayObject required attribute arrayObjectAttr');
    }

    return (
      <Root type={'arrayObject'}>
        {value.map((item: any, index: number) => {
          return (
            <React.Fragment key={index}>
              {item[attr.arrayObjectAttr as string]}
              <br />
            </React.Fragment>
          );
        })}
      </Root>
    );
  }

  if (type === 'datetime') {
    return <Root type={'datetime'}>{new Date(value).toLocaleString('ja-jp')}</Root>;
  }

  if (type === 'date') {
    return <Root type={'date'}>{new Date(value).toLocaleDateString('ja-jp')}</Root>;
  }

  if (type === 'year') {
    return <Root type={'date'}>{value ? value + '年' : ''}</Root>;
  }

  if (type === 'postCode') {
    return <Root type={'postCode'}>{value ? value.substring(0, 3) + ' - ' + value.substring(3) : ''}</Root>;
  }

  if (type === 'richText') {
    return (
      <Root type={'richText'}>
        <Box
          sx={{
            img: {
              maxWidth: '100%',
            },
            figure: {
              margin: 0,
            },
          }}
          dangerouslySetInnerHTML={{ __html: value as string }}
        />
      </Root>
    );
  }

  if (type === 'select' || type === 'checkbox' || type === 'radio') {
    if (!attr.options) {
      throw new Error('FormInputValue type select | checkbox | radio  required attribute options');
    }
    return <Root type={'richText'}>{attr.options.find((option: any) => option.id === value)?.label ?? ''}</Root>;
  }

  return <Root type={type}>{typeof value === 'object' ? JSON.stringify(value) : value}</Root>;
};
