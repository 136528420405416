import * as React from 'react';
import { useState } from 'react';
import { Show, IFormInputValue, RecordInfo } from '../../components/molucules/custom-react-admin';
import { ModalCancel } from '../../components/molucules/modal/modal-cancel';
import { useRefresh, useNotify, useRecordContext } from 'react-admin';
import { axiosBackendClient } from '../../util/axios';
import { CommonButton } from '../../components/atoms/button/common-button';
import { CustomLink } from '../../components/atoms/custom-link';
import { SALE_STATE } from './list';

const attrs: IFormInputValue[] = [
  { label: '注文ID', name: 'id' },
  { label: '購入者ID', name: 'purchaserId' },
  {
    label: '出品者ID',
    name: 'exhibitorId',
    render: (record) => {
      return (
        <CustomLink resource={'accounts'} type={'show'} id={record.exhibitorAccountId} title={record.exhibitorId} />
      );
    },
  },
  {
    label: '作品ID',
    name: 'artworkId',
    render: (record) => {
      return <CustomLink resource={'artworks'} type={'show'} id={record.artworkId} title={record.artworkId} />;
    },
  },
  { label: '配送ID', name: 'deliveryId' },
  { label: 'ヤマト運輸取引ID', name: 'tradeId' },
  { label: 'Stripe取引ID', name: 'stripeTransactionId' },
  { label: '作品名', name: 'artworkName' },
  { label: 'アーティスト名', name: 'artistName' },
  { label: '出品者ニックネーム', name: 'exhibitorNickName' },
  { label: '購入者ニックネーム', name: 'purchaserNickName' },
  { label: '販売価格', name: 'price', type: 'number' },
  { label: '収益金(注文単位)', name: 'profit', type: 'number' },
  { label: '額縁有無', name: 'withFrame', type: 'boolean' },
  { label: '壁掛け可否', name: 'isWallHanging', type: 'boolean' },
  { label: '返却', name: 'isReturned', type: 'boolean' },
  { label: '作品状態', name: 'degradedState' },
  { label: '配送料', name: 'deliveryCharge', type: 'number' },
  {
    label: '配送ステータス',
    name: 'deliveryStatus',
    render: (record: any) => (record.deliveryStatus ? record.deliveryStatus : '未発送'),
  },
  { label: '配送完了日', name: 'deliveryCompletedAt', type: 'datetime' },
  { label: '購入日', name: 'purchaseDate', type: 'datetime' },
  {
    label: 'ステータス',
    name: 'saleState',
    render: (record) => {
      return record.saleState === SALE_STATE.salesRecorded ? '取引完了' : record.saleState;
    },
  },
];

const CancelButton = ({ onClick }: { onClick: (record: any) => void }) => {
  const record = useRecordContext();

  return (
    <>
      <CommonButton
        text={'キャンセル'}
        type={'button'}
        variant={'black'}
        className={''}
        onClick={() => {
          onClick(record);
        }}
      />
    </>
  );
};

export const PurchaseHistoryShow = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [isCallApi, setIsCallApi] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState<string>('');
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCancelPurchaseClick = (record: any) => {
    setId(record.id);
    setOpenModal(true);
  };

  const handleCancelPurchase = async () => {
    if (isCallApi) {
      return;
    }
    setIsCallApi(true);

    try {
      await axiosBackendClient.post<ResponseType>(
        '/purchase-histories/cancel/' + id,
        {},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      refresh();

      notify('キャンセル完了');
    } catch (e) {
      // @ts-ignore
      notify(e?.response?.data?.error || e?.message || 'server error', { type: 'error' });
    }

    setIsCallApi(false);
    setOpenModal(false);
  };

  return (
    <Show recordTitleAttribute={'id'} hasEdit={false} buttons={<CancelButton onClick={handleCancelPurchaseClick} />}>
      <RecordInfo data={attrs} />
      <ModalCancel id={id} open={openModal} onClose={handleCloseModal} onSubmit={handleCancelPurchase} />
    </Show>
  );
};
