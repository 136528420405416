import axios, { type AxiosRequestConfig } from 'axios';
import { ADMIN_API_BASE_URL } from '../constant/api-url';

const baseConfig: AxiosRequestConfig = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
  responseEncoding: 'utf8',
};

/**
 * Backend APIエンドポイント用のaxiosオブジェクト
 */
export const axiosBackendClient = axios.create({
  ...baseConfig,
  baseURL: ADMIN_API_BASE_URL,
});
axiosBackendClient.interceptors.request.use((config) => {
  const authParams = localStorage?.getItem('auth') || '';
  const { access_token } = JSON.parse(authParams);
  if (access_token) {
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
});
