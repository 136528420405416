import * as React from 'react';
import { ControllerProps } from 'react-hook-form';
import {
  VCheckboxGroup,
  VDatePicker,
  VRadio,
  VSelect,
  VTextArea,
  VTextField,
  VFileUpload,
  FormGroup,
  VRichTextEditor,
} from '../../atoms/form';

export type IFormInput = {
  name: string;
  label: string;
  type?:
    | 'text'
    | 'number'
    | 'textarea'
    | 'select'
    | 'radio'
    | 'checkbox'
    | 'custom'
    | 'hidden'
    | 'file'
    | 'datetime'
    | 'richTextEditor';
  render?: (record?: any) => React.ReactNode; // render custom component
  validation?: ControllerProps['rules'];
  required?: boolean;
  options?: { id: string; label: string }[]; // options for select | radio | checkbox
  inputProps?: Record<any, any>; // another props of component
};

type FormInputProps = IFormInput & {
  record?: any;
};

export const FormInput = (attr: FormInputProps): JSX.Element => {
  const type = attr.type || 'text';

  if (type === 'hidden') {
    return <></>;
  }

  if (attr.render) {
    return <>{attr.render(attr.record)}</>;
  }

  if (['select', 'radio', 'checkbox'].includes(type)) {
    if (!attr.options) {
      return <>...incomplete select</>;
    }
  }

  if (type === 'select') {
    return (
      <VSelect
        name={attr.name}
        label={attr.label}
        required={attr.required}
        validation={attr.validation}
        options={attr.options || []}
        {...attr.inputProps}
      />
    );
  }

  if (type === 'radio') {
    return (
      <VRadio
        name={attr.name}
        label={attr.label}
        row={true}
        options={attr.options || []}
        required={attr.required}
        {...attr.inputProps}
      />
    );
  }
  if (type === 'checkbox') {
    return (
      <VCheckboxGroup
        name={attr.name}
        label={attr.label}
        row={true}
        options={attr.options || []}
        required={attr.required}
        {...attr.inputProps}
      />
    );
  }

  if (type === 'textarea') {
    return (
      <VTextArea
        name={attr.name}
        label={attr.label}
        required={attr.required}
        validation={attr.validation}
        minRows={5}
        maxRows={5}
        {...attr.inputProps}
      />
    );
  }

  if (type === 'text') {
    return (
      <VTextField
        name={attr.name}
        label={attr.label}
        required={attr.required}
        validation={attr.validation}
        {...attr.inputProps}
      />
    );
  }

  if (type === 'file') {
    return (
      <FormGroup label={attr.label} required={attr.required}>
        <VFileUpload name={attr.name} required={attr.required} validation={attr.validation} {...attr.inputProps} />
      </FormGroup>
    );
  }

  if (type === 'datetime') {
    return (
      <VDatePicker
        name={attr.name}
        label={attr.label}
        required={attr.required}
        validation={attr.validation}
        {...attr.inputProps}
      />
    );
  }

  if (type === 'richTextEditor') {
    return (
      <VRichTextEditor
        name={attr.name}
        label={attr.label}
        required={attr.required}
        validation={attr.validation}
        {...attr.inputProps}
      />
    );
  }

  return <></>;
};
