import { ListItemIcon } from '@mui/material';
import { ArtistShow } from './show';
import { ArtistList } from './list';

export default {
  list: ArtistList,
  menuIcon: ListItemIcon,
  show: ArtistShow,
  options: { label: 'アーティスト管理' },
};
