import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
// import { seed } from './mocks/db';

/*
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  seed();
  worker.start({ onUnhandledRequest: 'bypass' });
  console.log('mock-api初期化');
}
 */

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
