/**
 * Noteコンテンツのカテゴリの識別子
 */
export const NoteCategory = {
  // お知らせ
  news: 'お知らせ',
  // コンテンツ
  feature: 'コンテンツ',
} as const;
export type NoteCategoryType = typeof NoteCategory[keyof typeof NoteCategory];

/**
 * Noteコンテンツの公開ステータス
 *
 * boolean(2値)で表現する形式とすると状態が増えた時に対応できないため、識別子で表現する。
 */
export const NoteOpenStatus = {
  // 公開
  open: '公開',
  // 非公開
  close: '非公開',
} as const;
export type NoteOpenStatusType = typeof NoteOpenStatus[keyof typeof NoteOpenStatus];

/**
 * Noteコンテンツ
 */
export interface INote {
  // 種別
  id: string;
  // カテゴリ
  category: NoteCategoryType;
  // サムネイル画像のURL (XXXXXXX.jpgといった形式)
  thumbnailImageUrl?: string;
  // タイトル
  title: string;
  // 本文
  body: string;
  // 公開ステータス
  openStatus: NoteOpenStatusType;
  // 公開日時
  openedAt?: Date;
}
