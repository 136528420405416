import React, { useState } from 'react';
import { FormControlLabelProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ja from 'date-fns/locale/ja';
import { Control, ControllerProps, FieldError, useFormContext, useWatch } from 'react-hook-form';
import { VTextField } from '../index';
import format from 'date-fns/format';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const theme = createTheme({
  components: {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& > :first-of-type': {
            position: 'relative',
            '& > :first-of-type': {
              margin: '0 auto',
              zIndex: 2,
              '& .MuiIconButton-root': { display: 'none' },
            },
            '& .MuiPickersArrowSwitcher-root': {
              position: 'absolute',
              zIndex: 1,
              right: 0,
              left: 0,
              display: 'inline-block',
              '& .MuiIconButton-edgeStart': {
                float: 'right',
              },
              '& .MuiIconButton-edgeEnd': {
                float: 'left',
              },
            },
          },
          '&.MuiCalendarPicker-custom': {
            maxHeight: '420px !important',
            overflowX: 'auto',
            '& .PrivatePickersSlideTransition-root': {
              minHeight: '320px',
              overflowX: 'auto',
            },
            '& .MuiPickersDay-root': {
              position: 'relative',
              marginTop: '15px',
              '&.MuiPickersDay-border': {
                border: '1px solid rgba(0, 0, 0, 0.6)',
              },
              '&.MuiPickersDay-noBorder': {
                border: 0,
              },
              '&.MuiPickersDay-desiredDeliveryDate:before': {
                content: '"配達希望日"',
                display: 'inline-block',
                position: 'absolute',
                top: '-15px',
                width: '60px',
                fontSize: '10px',
                color: '#000',
              },
              '&.MuiPickersDay-desiredPickUpDate:before': {
                content: '"集荷希望日"',
                display: 'inline-block',
                position: 'absolute',
                top: '-15px',
                width: '60px',
                fontSize: '10px',
                color: '#000',
              },
              '&.MuiPickersDay-orderDate:before': {
                content: '"注文日"',
                display: 'inline-block',
                position: 'absolute',
                top: '-15px',
                width: '50px',
                fontSize: '10px',
                color: '#000',
              },
            },
          },
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#000 !important',
        },
        body1: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
        body2: {
          fontSize: '14px',
          fontWeight: 300,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: 'black',
          fontSize: '2.5em',
          height: '0.5em',
          width: '0.5em',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        dayOutsideMonth: {
          width: '12.35vw',
          margin: '0 2px',
          color: '#000',
          fontSize: '14px',
          '@media (min-width: 768px)': {
            width: '36px',
          },
        },
      },
    },
  },
  palette: {
    primary: { light: '#000', main: '#000', dark: '#000' },
  },
});

class LocalizedDateFnsAdapter extends AdapterDateFns {
  getWeekdays: () => string[] = () => {
    return ['日', '月', '火', '水', '木', '金', '土'];
  };

  constructor(props: any) {
    super({ ...props, formats: { ...props.formats, monthAndYear: 'yyyy年LLLL' } });
  }
}

type Props = Omit<DatePickerProps<any, any>, 'renderInput' | 'onChange' | 'value'> & {
  name: string;
  date?: Date;
  validation?: ControllerProps['rules'];
  label?: string;
  parseError?: (error: FieldError) => string;
  onChange?: (value: any) => void;
  control?: Control<any>;
  onError?: (error: FieldError | undefined) => void;
  errorLabel?: FormControlLabelProps['label'];
  showRequiredText?: boolean;
  required?: boolean;
};

export const VDatePicker = ({
  name,
  label,
  parseError,
  control,
  onError,
  errorLabel,
  showRequiredText = true,
  validation = {},
  required = false,
  ...rest
}: Props) => {
  const { setValue, trigger } = useFormContext();
  const value = useWatch({ name: name });

  const [open, setOpen] = useState(false);

  const handleChange = (value: any) => {
    if (value && !isNaN(value)) {
      console.log('handleChange', value);
      setValue(name, format(value, 'yyyy-MM-dd'));
      trigger(name);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={LocalizedDateFnsAdapter} adapterLocale={ja}>
        <DatePicker
          {...rest}
          onChange={handleChange}
          value={value || ''}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => {
            const { inputProps, InputProps, ...rest } = params;
            return (
              <VTextField
                {...rest}
                InputProps={{ ...InputProps, readOnly: true }}
                inputProps={{ ...inputProps, value: value ? inputProps?.value : '', readOnly: true }}
                name={name}
                label={label}
                parseError={parseError}
                control={control}
                onError={onError}
                errorLabel={errorLabel}
                showRequiredText={showRequiredText}
                validation={validation}
                required={required}
                onClick={() => {
                  setOpen(true);
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
