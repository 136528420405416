import { Edit, Form, IFormInput } from 'src/components/molucules/custom-react-admin';
import * as React from 'react';
import { NoteFormData } from './create';

const FormData: IFormInput[] = [...NoteFormData, { type: 'hidden', name: 'id', label: 'id' }];

const NoteEdit = () => {
  return (
    <Edit recordTitleAttribute="title">
      <Form data={FormData} />
    </Edit>
  );
};

export default NoteEdit;
