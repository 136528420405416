import { ComponentWithoutChildren } from '../../types/component';
import { ImageFileMIMEType } from '../../util/files';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';

type Props = {
  label: JSX.Element | string;
  dropAction: (file?: File) => void;
  accept: Array<ImageFileMIMEType>;
  noDrag?: boolean;
  disabled?: boolean;
};

const FileDropzone: ComponentWithoutChildren<Props> = ({
  label,
  accept,
  dropAction,
  noDrag = false,
  disabled = false,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: accept.join(','),
    onDrop: (acceptedFiles: Array<File>) => dropAction(acceptedFiles.pop()),
    noDrag,
    disabled,
  });
  return (
    <Box
      {...getRootProps()}
      sx={{
        padding: 1,
        minHeight: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        border: '1px dashed rgba(0, 0, 0, 0.23)',
        bgcolor: 'rgba(0, 0, 0, 0.03)',
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.6)',
        cursor: 'pointer',
      }}
    >
      <input {...getInputProps()} />
      {typeof label === 'string' ? <p>{label}</p> : label}
    </Box>
  );
};

export default FileDropzone;
