import React, { useState } from 'react';
import { FormControlLabelProps, FormHelperText, Grid, InputLabel, TextField, TextFieldProps } from '@mui/material';
import { Control, Controller, ControllerProps, FieldError } from 'react-hook-form';
import transformValidation from '../common/validation';
import RequiredLabel from '../required-label';
import { FORM_GRID, INPUT_STYLE, LABEL_STYLE } from '../../../../util/constant/form';
import { className } from '../../../../util/function';

export type VTextFieldProps = Omit<TextFieldProps, 'name'> & {
  validation?: ControllerProps['rules'];
  name: string;
  parseError?: (error: FieldError) => string;
  control?: Control<any>;
  onError?: (error: FieldError | undefined) => void;
  errorLabel?: FormControlLabelProps['label'];
  showRequiredText?: boolean;
  preventNegativeNumber?: boolean;
  preventDot?: boolean;
};

export default function VTextField({
  validation = {},
  parseError,
  type,
  required,
  name,
  control,
  showRequiredText = true,
  label,
  preventNegativeNumber,
  preventDot,
  errorLabel,
  onKeyDown,
  ...rest
}: VTextFieldProps): JSX.Element {
  // @ts-ignore
  validation = transformValidation(validation, errorLabel || label, required);
  const [isFocus, setIsFocus] = useState(false);

  const handleKeydown = (e: any) => {
    if (type === 'number' && preventNegativeNumber) {
      if (['-', '+', 'e'].includes(e.key)) {
        e.preventDefault();
        return;
      }
    }

    if (type === 'number' && preventDot) {
      if (['.'].includes(e.key)) {
        e.preventDefault();
        return;
      }
    }

    return onKeyDown && onKeyDown(e);
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => {
        rest.onError && rest.onError(error);

        return (
          <Grid container className={className({ hasLabel: !!label, formGridContainer: true })} sx={FORM_GRID}>
            {label && (
              <Grid item className={'formGridLabel'}>
                <InputLabel focused={isFocus} error={!!error} sx={LABEL_STYLE}>
                  {label} {required && showRequiredText ? <RequiredLabel /> : ''}
                </InputLabel>
              </Grid>
            )}
            <Grid item className={'formGridInput'}>
              <TextField
                {...rest}
                label={''}
                name={name}
                value={value || ''}
                onChange={(e) => {
                  if (type === 'number' && preventNegativeNumber) {
                    if (Number(e.target.value) < 0) {
                      return;
                    }
                  }

                  if (type === 'number' && preventDot) {
                    e.target.value = e.target.value.replace('.', '');
                  }

                  rest.onChange && rest.onChange(e);
                  return onChange(e);
                }}
                onBlur={(e) => {
                  setIsFocus(false);
                  rest.onBlur && rest.onBlur(e);
                  return onBlur;
                }}
                onFocus={() => {
                  setIsFocus(true);
                }}
                required={required}
                type={type}
                error={invalid}
                sx={INPUT_STYLE}
                fullWidth
                variant={'outlined'}
                helperText={rest.helperText}
                onKeyDown={handleKeydown}
                className={className({ validateError: !!error, VTextField: true })}
              />
              {error && (
                <FormHelperText error={true}>
                  {typeof parseError === 'function' ? parseError(error) : error.message}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
