import { CreateBase, Title } from 'react-admin';
import { Box, Card, Typography } from '@mui/material';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { UseMutationOptions } from 'react-query';
import { RaRecord, RedirectionSideEffect, TransformData, UseCreateMutateParams } from 'ra-core';
import { SxProps } from '@mui/system';

type CreateBaseProps<RecordType extends RaRecord = any, MutationOptionsError = unknown> = {
  className?: string;
  disableAuthentication?: boolean;
  hasEdit?: boolean;
  hasShow?: boolean;
  record?: Partial<RecordType>;
  redirect?: RedirectionSideEffect;
  resource?: string;
  mutationOptions?: UseMutationOptions<RecordType, MutationOptionsError, UseCreateMutateParams<RecordType>> & {
    meta?: any;
  };
  transform?: TransformData;
  title?: string | ReactElement;
  sx?: SxProps;
};

type Props = CreateBaseProps & {
  children?: ReactNode;
  title?: string | ReactElement;
};

export const Create = ({ children, title, ...props }: Props) => {
  return (
    <Box sx={styles}>
      <CreateBase {...props}>
        <Card className={'createContainer'}>
          <Title title={title} />
          {title && (
            <Typography variant={'h6'} className={'createTitle'}>
              {title}
            </Typography>
          )}
          <Box className={'create'}>{children}</Box>
        </Card>
      </CreateBase>
    </Box>
  );
};

const styles = {
  padding: '50px 25px',
  '& .createContainer': {
    padding: '20px',
    paddingBottom: '50px',
  },
  '& .MuiCard-root': {
    borderRadius: 0,
  },
  '& .createTitle': {
    paddingBottom: '10px',
    borderBottom: '1px solid #E6E9ED',
    color: '#73879D;',
  },

  '& .create': {
    marginTop: '20px',
    '& .formGridLabel': {
      '& .MuiInputLabel-root': {
        textAlign: 'left',
      },
    },
  },
};
