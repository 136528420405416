import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCreatePath } from 'react-admin';

interface ICustomLink {
  resource: string;
  title: string;
  type: string;
  id?: string;
}

export const CustomLink = ({ title, ...props }: ICustomLink): JSX.Element => {
  const createPath = useCreatePath();
  return (
    <Box
      sx={{
        display: 'inline',
        '& a': {
          textDecoration: 'none',
          color: '#3954FF',
        },
        '& a:visited': {
          textDecoration: 'none',
          color: '#3954FF',
        },
      }}
    >
      <Link to={createPath({ ...props })}>{title}</Link>
    </Box>
  );
};
