import React from 'react';
import { SvgIcon } from '@mui/material';

interface ICheckBoxCheckedIcon {
  color?: string;
  border?: string;
}

export default function CheckboxChecked({ color = '#fff', border = '#000' }: ICheckBoxCheckedIcon): JSX.Element {
  return (
    <SvgIcon fontSize={'inherit'} viewBox={'0 0 25 25'}>
      <g stroke={border} strokeWidth="1">
        <rect width="25" height="25" stroke="none" />
        <rect x="0.5" y="0.5" width="24" height="24" fill="none" />
      </g>
      <path
        d="M5.443,11.917l3.947,3.947L15.92,9.335"
        transform="translate(2.056 0.165)"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
