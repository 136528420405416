import React from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormHelperText, Grid, InputLabel, RadioGroup } from '@mui/material';
import transformValidation from '../../../atoms/form/common/validation';
import CheckboxIcon from '../../../atoms/icon/checkbox';
import CheckboxCheckedIcon from '../../../atoms/icon/checkbox-checked';
import { VCheckboxProps } from '../../../atoms/form';
import RequiredLabel from '../../../atoms/form/required-label';
import { FORM_GRID, LABEL_STYLE } from '../../../../util/constant/form';
import { className } from '../../../../util/function';

export type VCheckboxGroupProps = VCheckboxProps & {
  options: any[];
  row?: boolean;
  labelKey?: string;
  valueKey?: string;
  returnObject?: boolean;
  onChange?: (value: any[]) => void;
  showRequiredText?: boolean;
};

export default function VCheckboxGroup({
  validation,
  helperText,
  options,
  label,
  errorLabel,
  name,
  parseError,
  required,
  labelKey = 'label',
  valueKey = 'id',
  returnObject,
  disabled,
  row,
  control,
  showRequiredText = true,
  ...rest
}: VCheckboxGroupProps): JSX.Element {
  // @ts-ignore
  validation = transformValidation(validation, errorLabel || label, required);

  const {
    field: { value = [], onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    rules: validation,
    control,
  });

  const handleChange = (index: number | string) => {
    const changedValue = Array.isArray(value) ? value : [value];

    const newArray = [...changedValue];
    const exists = changedValue.findIndex((i: any) => (returnObject ? i[valueKey] === index : i === index)) === -1;

    if (exists) {
      newArray.push(returnObject ? options.find((i) => i[valueKey] === index) : index);
    } else {
      newArray.splice(
        changedValue.findIndex((i: any) => (returnObject ? i[valueKey] === index : i === index)),
        1,
      );
    }
    onChange(newArray);
    rest.onChange && rest.onChange(newArray);
  };

  return (
    <Grid container className={className({ hasLabel: !!label })} sx={FORM_GRID}>
      {label && (
        <Grid item className={'formGridLabel'}>
          <InputLabel error={!!error} sx={LABEL_STYLE}>
            {label} {required && showRequiredText ? <RequiredLabel /> : ''}
          </InputLabel>
        </Grid>
      )}
      <Grid item className={'formGridInput'}>
        <RadioGroup row={row}>
          {options.map((option) => {
            const optionKey = option[valueKey];
            if (!optionKey) {
              console.error(`CheckboxButtonGroup: valueKey ${valueKey} does not exist on option`, option);
            }
            const changedValue = Array.isArray(value) ? value : [value];
            const isChecked =
              changedValue.findIndex((item: any) =>
                returnObject ? item[valueKey] === optionKey : item === optionKey,
              ) !== -1;

            return (
              <FormControlLabel
                control={
                  <Checkbox
                    icon={option.icon ? option.icon : <CheckboxIcon />}
                    checkedIcon={option.checkedIcon ? option.checkedIcon : <CheckboxCheckedIcon />}
                    sx={{
                      color: invalid ? 'error.main' : 'undefined',
                      '& .MuiSvgIcon-root': {
                        borderColor: '#999',
                      },
                      '&.Mui-checked': {
                        color: '#000',
                      },
                      '& svg': {
                        width: '25px',
                        height: '25px',
                      },
                    }}
                    value={optionKey}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={() => handleChange(optionKey)}
                  />
                }
                label={option[labelKey]}
                key={optionKey}
              />
            );
          })}
        </RadioGroup>
        {helperText && <FormHelperText sx={{ margin: '3px 0 0' }}>{helperText}</FormHelperText>}
        {error && (
          <FormHelperText error={invalid} sx={{ margin: '3px 0 0' }}>
            {typeof parseError === 'function' ? parseError(error) : error.message}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}
